import React, { FC } from 'react'
import { colors } from 'src/static/theme'
import styled from 'styled-components'
import { Tag } from '@JOTAJornalismo/jota-design-system'

type CategoryNameProps = {
    name: string
    icon: string
    vertical: string
    color:
        | 'green'
        | 'white'
        | 'black'
        | 'gray'
        | 'red'
        | 'orange'
        | 'yellow'
        | 'blue'
        | 'cyan'
        | 'purple'
}

const CategoryName: FC<CategoryNameProps> = ({
    name,
    icon,
    vertical = 'Tributos',
    color = 'green',
}) => {
    return (
        <Container>
            <Tag
                label={vertical}
                size='medium'
                type='filled'
                color={color}
                disableHover
            />
            <Name color={color}>{name}</Name>
        </Container>
    )
}

/**
 * Given a color name, return the corresponding color
 * @param {string} color - The color of the button.
 * @return {string} An array of colors
 */
export const getColorsByName = (color) => {
    switch (color) {
        case 'blue':
            return colors.blue.jotaBlue
        case 'green':
            return colors.green.jotaGreen
    }
}

export default CategoryName

const Container = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    align-items: center;
`

export const Name = styled.h6<{ color: string; reset: boolean }>`
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: ${(props) =>
        props.color ? getColorsByName(props.color) : colors.black.jotaBlack};
    margin: 0;
    padding: 0;
    margin-left: ${(props) => (props.reset ? 'inherit' : '8px')};
`
