import { hotjar } from 'react-hotjar'

type UserType = {
    email: string
    name: string
    roles: any
}

/**
 * HOTJAR Integration
 * Official Site: https://www.hotjar.com/
 * Github Lib: https://github.com/abdalla/react-hotjar
 * Description: Bootstrap hotjar application
 * @param {UserType} user
 * @param {string} statePage
 * @param {string} event
 * return {void}
 */
function hotjarStart(
    user: UserType,
    statePage: string,
    event: string = 'button-click'
) {
    const ClientID: number =
        (process.env.REACT_APP_HOTJAR_CLIENT_ID &&
            parseInt(process.env.REACT_APP_HOTJAR_CLIENT_ID)) ||
        0

    const Version: number =
        (process.env.REACT_APP_HOTJAR_VERSION &&
            parseInt(process.env.REACT_APP_HOTJAR_VERSION)) ||
        6

    // Hotjar ID
    hotjar.initialize(ClientID, Version)

    // Identify the user
    hotjar.identify('USER_ID', {
        email: user.email,
        name: user.name,
        roles: user.roles,
    })

    // Add an event
    hotjar.event(event)

    // Update SPA state
    hotjar.stateChange(statePage)
}
export default hotjarStart
