import React from 'react'
import styled from 'styled-components'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import JOTA from './../static/img/jotapro.png'

const Loading = () => {
    return (
        <Container>
            <SkeletonTheme baseColor='#f9f9f9' highlightColor='#e1e1e1'>
                <JOTAImg src={JOTA} alt='jota logo' />
                <Skeleton
                    height='15px'
                    width='150px'
                    style={{ margin: '2px' }}
                    count={1}
                />
            </SkeletonTheme>
        </Container>
    )
}

export default Loading

const Container = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    background: white;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    padding: 0em;
    align-items: center;
    text-transform: uppercase;
`

const JOTAImg = styled.img`
    width: 250px;
    margin-bottom: 20px;
    opacity: 0.1;
`
