import { combineReducers } from 'redux'
import {
    authReducer,
    menuReducer,
    newslettersReducer as newsletterReducer,
} from './../ducks'

const rootReducer = combineReducers({
    auth: authReducer,
    newsletters: newsletterReducer,
    menu: menuReducer,
})

export type AppState = ReturnType<typeof rootReducer>

export default rootReducer
