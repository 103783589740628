import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import styled from 'styled-components'
import 'react-loading-skeleton/dist/skeleton.css'
import { MOBILE_SIZE } from '../../static/theme'

const NewslettersCategories = () => (
    <SkeletonTheme baseColor='#f9f9f9' highlightColor='#e1e1e1'>
        <LoadingGrid>
            <SkeletonItemList />
            <SkeletonItemList />
            <SkeletonItemList />
            <SkeletonItemList />
            <SkeletonItemList />
            <SkeletonItemList />
            <SkeletonItemList />
            <SkeletonItemList />
            <SkeletonItemList />
            <SkeletonItemList />
        </LoadingGrid>
    </SkeletonTheme>
)

const SkeletonItemList = () => (
    <div>
        <SkeletonOverride
            height='25px'
            width='450px'
            widthMobile='100%'
            style={{ margin: '2px' }}
            count={2}
        />
        <SkeletonOverride
            height='15px'
            width='250px'
            widthMobile='50%'
            style={{ margin: '2px' }}
            count={1}
        />
    </div>
)

export default NewslettersCategories

const LoadingGrid = styled.div`
    display: grid;
    grid-template-areas: 'column1 column2';
    grid-gap: 36px;
    box-sizing: border-box;
    margin-top: 36px;

    @media (max-width: ${MOBILE_SIZE}) {
        display: flex;
        flex-direction: column;
    }
`

const SkeletonOverride = styled(Skeleton)<{
    width: string
    height: string
    widthMobile: string
}>`
    width: ${(props) => props.width} !important;
    height: ${(props) => props.height} !important;

    @media (max-width: ${MOBILE_SIZE}px) {
        width: ${(props) => props.widthMobile} !important;
    }
`
