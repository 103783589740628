import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { colors, fonts, MOBILE_SIZE } from 'src/static/theme'
import { Header, MenuComponent, PasswordChangeForm } from 'src/components'
import {
    Tab,
    Input,
    Button,
    Tag,
    Dialog,
} from '@JOTAJornalismo/jota-design-system'
import { ReactComponent as UserIcon } from './../static/icons/menu/user.svg'
import { Auth } from 'aws-amplify'
import {
    Container,
    Content,
    LayoutDistribution,
    LayoutTop,
    Row,
} from 'src/components/page-structure'
import {
    profileGetUser,
    setProfileData,
    USERINTERFACE,
} from './../flux/ducks/auth'
import { axiosJOTA, ProfileAPI } from './../flux/api'
import { COGNITO_RESPONSES } from './../utils/constants'
import { FirstLoginStateI } from './auth/login'

type NewslettersProps = {
    menu: any
    user: any
    userBOProps: any
    getProfileUser: Function
    setProfileDataProps: Function
}

const TIME_FADEOUT = 5000

const ProfilePage: FC<NewslettersProps> = ({
    menu,
    user,
    getProfileUser,
    userBOProps,
    setProfileDataProps,
}) => {
    const [userBO, setUserBO] = useState<{
        givenName: ''
        familyName: ''
        email: ''
    }>({ givenName: '', familyName: '', email: '' })

    const [userField, setUserField] = useState<{
        familyName: ''
        givenName: ''
    }>({ familyName: '', givenName: '' })

    const [password, setPassword] = useState<{
        actual: string
        new: string
        retype: string
        reset: boolean
    }>({ actual: '', new: '', retype: '', reset: false })

    const [disableButtons, setDisableButtons] = useState<{
        user: boolean
        password: boolean
    }>({ user: true, password: false })

    const [showContent, setshowContent] = useState<{
        dialog: boolean
        password: boolean
        passRequirements: boolean
        dialogPass: boolean
        actualPasswordStatus: string
    }>({
        dialog: false,
        password: false,
        passRequirements: false,
        dialogPass: true,
        actualPasswordStatus: '',
    })

    const [passwordRules, setPasswordRules] = useState<{
        actualPass: boolean | null
    }>({
        actualPass: null,
    })

    const [plans, setPlans] = useState<string[]>([])
    const [dataStatus, setDataStatus] = useState<{
        nome: string
        sobrenome: string
    }>({
        nome: '',
        sobrenome: '',
    })

    const [firstLoginState, setFirstLoginState] = useState<FirstLoginStateI>({
        passwordStatus: false,
        password: '',
        reset: false,
    })

    /**
     * User Attributes
     * Detect when attributes change
     */
    useEffect(() => {
        getProfileUser()

        return () => {
            setUserBO({
                givenName: '',
                familyName: '',
                email: '',
            })

            setUserField({
                givenName: '',
                familyName: '',
            })
        }
    }, [user])

    /**
     * Use Effect
     * Get User Data from Backoffice
     * -> Plans
     */
    useEffect(() => {
        if (user.attributes && userBOProps) {
            setUserBO({
                givenName: userBOProps.first_name,
                familyName: userBOProps.last_name,
                email: user.attributes.email,
            })

            setUserField({
                givenName: userBOProps.first_name,
                familyName: userBOProps.last_name,
            })
        }

        getPlansUser()
    }, [userBOProps])

    /**
     * Password Data
     * Detect when user input password actual
     */
    useEffect(() => {
        if (password.actual !== '') {
            setDisableButtons({
                ...disableButtons,
                password: false,
            })
        }
    }, [password])

    /**
     * Change Input
     * Family and Given Name
     * @param {any} e
     * @param {string} type
     */
    const changeInput = (e: any, type: string) => {
        setUserField({
            ...userField,
            [type]: e,
        })

        if (
            userBO.familyName !== userField.familyName ||
            userBO.givenName !== userField.givenName
        ) {
            setDisableButtons({
                ...disableButtons,
                user: false,
            })
        }

        setDataStatus({
            ...dataStatus,
            sobrenome:
                userField.familyName === ''
                    ? COGNITO_RESPONSES.INPUT_NOT_EMPTY
                    : '',
            nome:
                userField.givenName === ''
                    ? COGNITO_RESPONSES.INPUT_NOT_EMPTY
                    : '',
        })
    }

    /**
     * Reset Data
     * User click on cancel button
     */
    const resetData = () => {
        setDisableButtons({
            ...disableButtons,
            user: true,
        })
        setUserField({
            ...userField,
            familyName: userBO.familyName,
            givenName: userBO.givenName,
        })
    }

    /**
     * Update User Data
     * User click on save
     */
    const updateUserData = () => {
        // update on redux - until next login
        setProfileDataProps({
            givenName: userField.givenName,
            familyName: userField.familyName,
        })

        // update on Backoffice (and Cognito)
        axiosJOTA
            .patch(ProfileAPI.me(), {
                first_name: userField.givenName,
                last_name: userField.familyName,
                profile: {},
            })
            .then(() => {
                setshowContent({
                    ...showContent,
                    dialog: true,
                })

                showForSeconds('dialog-user')
            })
    }

    /**
     * Change Password
     * Input Event
     * @param {any} e
     * @param {string} type
     */
    const changePassword = (e: any, type: string) => {
        setPassword({
            ...password,
            [type]: e,
        })
    }

    /**
     * Reset Password Button
     */
    const resetPassword = () => {
        setPassword({
            actual: '',
            new: '',
            retype: '',
            reset: true,
        })

        setshowContent({
            ...showContent,
            passRequirements: false,
            password: false,
            actualPasswordStatus: '',
        })

        setFirstLoginState({
            ...firstLoginState,
            reset: true,
        })
    }

    const showForSeconds = (el: string) => {
        const elTemp = document.getElementById(el)
        elTemp!.style.display = 'block'

        setTimeout(() => {
            elTemp!.style.display = 'none'
        }, TIME_FADEOUT)
    }

    /**
     * Change Password
     * COGNITO
     */
    const changePasswordCognito = async () => {
        Auth.currentAuthenticatedUser()
            .then((userCognito) => {
                return Auth.changePassword(
                    userCognito,
                    password.actual,
                    firstLoginState.password
                )
            })
            .then(() => {
                setshowContent({
                    ...showContent,
                    dialogPass: true,
                    password: false,
                })

                showForSeconds('dialog-password')
            })
            .catch((e: any) => {
                if (e.code === 'LimitExceededException') {
                    setshowContent({
                        ...showContent,
                        actualPasswordStatus:
                            COGNITO_RESPONSES.INPUT_ACTUAL_PASS_EXCEEDED,
                    })
                } else {
                    setshowContent({
                        ...showContent,
                        actualPasswordStatus:
                            COGNITO_RESPONSES.INPUT_ACTUAL_PASS_WRONG,
                    })
                }
                setPasswordRules({ ...passwordRules, actualPass: false })
            })
    }

    /**
     * Actual Password • Status
     * @param {string} type
     * @return {boolean}
     */
    const actualPasswordStatus = () => {
        return passwordRules.actualPass === false ? 'error' : 'normal'
    }

    /**
     * Get Plans from user
     */
    const getPlansUser = () => {
        if (userBOProps && userBOProps.profile && userBOProps.profile.plan) {
            const plansTemp = userBOProps.profile.plan
                .split(',')
                .map((item) => item.trim())
            setPlans(plansTemp)
        }
    }

    const buttonSubmitStatus = () => {
        if (firstLoginState.passwordStatus && password.actual) {
            return true
        }
        return false
    }

    return (
        <Container>
            <Header term='' />
            <Row>
                <MenuComponent />
                <Content menuIsOpen={menu.isOpen}>
                    <LayoutDistribution>
                        <LayoutTop>
                            <TitleSection>Configurações da Conta</TitleSection>
                            <TabContainer>
                                <Tab
                                    LeftIcon={UserIcon}
                                    label='Informações Cadastrais'
                                    type='active'
                                />
                            </TabContainer>

                            <SubTitle>Perfil</SubTitle>

                            <UserPerfil>
                                <FormUser>
                                    <Input
                                        label='Nome'
                                        type='text'
                                        alternativeText={dataStatus.nome}
                                        status={
                                            dataStatus.nome === ''
                                                ? 'normal'
                                                : 'error'
                                        }
                                        showPrefixIcon={false}
                                        defaultValue={userField.givenName}
                                        onChange={(e) =>
                                            changeInput(e, 'givenName')
                                        }
                                    />
                                    <Input
                                        label='Sobrenome'
                                        type='text'
                                        alternativeText={dataStatus.sobrenome}
                                        status={
                                            dataStatus.sobrenome === ''
                                                ? 'normal'
                                                : 'error'
                                        }
                                        showPrefixIcon={false}
                                        defaultValue={userField.familyName}
                                        onChange={(e) =>
                                            changeInput(e, 'familyName')
                                        }
                                    />

                                    <ButtonsForm>
                                        <Button
                                            label='Cancelar'
                                            size='medium'
                                            type='outlined'
                                            disabled={disableButtons.user}
                                            width='auto'
                                            onClick={() => resetData()}
                                        />
                                        <Button
                                            label='Salvar Alterações'
                                            size='medium'
                                            type='filled'
                                            disabled={disableButtons.user}
                                            width='auto'
                                            onClick={() => updateUserData()}
                                        />
                                    </ButtonsForm>
                                </FormUser>
                                {showContent.dialog && (
                                    <DialogForm id='dialog-user'>
                                        <Dialog
                                            message='Dados alterados com sucesso.'
                                            type='success'
                                        />
                                    </DialogForm>
                                )}
                                <UserData>
                                    <UserDataBlock>
                                        <UserLabel>E-mail cadastrado</UserLabel>
                                        <UserValue>{userBO.email}</UserValue>
                                    </UserDataBlock>

                                    <UserDataBlock>
                                        {showContent.password ? (
                                            <PasswordChange>
                                                <InputPass>
                                                    <Input
                                                        label='Senha Atual'
                                                        type='password'
                                                        showPrefixIcon={false}
                                                        status={actualPasswordStatus()}
                                                        alternativeText={
                                                            showContent.actualPasswordStatus
                                                        }
                                                        defaultValue={
                                                            password.actual
                                                        }
                                                        onChange={(e) =>
                                                            changePassword(
                                                                e,
                                                                'actual'
                                                            )
                                                        }
                                                    />
                                                </InputPass>

                                                <PasswordChangeForm
                                                    state={firstLoginState}
                                                    setState={
                                                        setFirstLoginState
                                                    }
                                                    reset={
                                                        firstLoginState.reset
                                                    }
                                                />

                                                <ButtonsForm>
                                                    <Button
                                                        label='Cancelar'
                                                        size='medium'
                                                        type='outlined'
                                                        disabled={
                                                            !password.actual
                                                        }
                                                        width='auto'
                                                        onClick={() =>
                                                            resetPassword()
                                                        }
                                                    />
                                                    <Button
                                                        label='Salvar Alterações'
                                                        size='medium'
                                                        type='filled'
                                                        disabled={
                                                            !buttonSubmitStatus()
                                                        }
                                                        width='auto'
                                                        onClick={() =>
                                                            changePasswordCognito()
                                                        }
                                                    />
                                                </ButtonsForm>
                                            </PasswordChange>
                                        ) : (
                                            <PasswordNormal>
                                                <UserLabel>
                                                    Senha Atual
                                                </UserLabel>
                                                <UserValue>********</UserValue>
                                                <Tag
                                                    label='Alterar Senha'
                                                    onClick={() => {
                                                        resetPassword()
                                                        setshowContent({
                                                            ...showContent,
                                                            password: true,
                                                        })
                                                    }}
                                                    size='medium'
                                                    color='orange'
                                                    type='colored'
                                                />
                                            </PasswordNormal>
                                        )}
                                        {showContent.dialogPass && (
                                            <DialogForm id='dialog-password'>
                                                <Dialog
                                                    message={
                                                        COGNITO_RESPONSES.PASS_SUCCESS
                                                    }
                                                    type='success'
                                                />
                                            </DialogForm>
                                        )}
                                    </UserDataBlock>
                                </UserData>
                            </UserPerfil>

                            <SubTitle>Meus Planos</SubTitle>

                            <UserPlanos>
                                <Tag
                                    label='JOTA'
                                    onClick={() => {
                                        return false
                                    }}
                                    size='medium'
                                    color='orange'
                                    type='filled'
                                    disableHover
                                />
                                <UserPlanosList>
                                    {plans.map((item, key) => (
                                        <UserPlanoListItem key={key}>
                                            {item}
                                        </UserPlanoListItem>
                                    ))}
                                </UserPlanosList>
                            </UserPlanos>
                        </LayoutTop>
                    </LayoutDistribution>
                </Content>
            </Row>
        </Container>
    )
}

/**
 * Map State to Props
 * @param {state} state
 * @return {redux}
 */
const mapStateToProps = (state: any) => ({
    user: state.auth.user,
    roles: state.auth.roles,
    menu: state.menu,
    userBOProps: state.auth.userBO,
})

/**
 * Map Dispatch to Props
 * @param {dispatch} dispatch
 * @return {event}
 */
const mapDispatchToProps = (dispatch: any) => ({
    getProfileUser: () => dispatch(profileGetUser()),
    setProfileDataProps: (data: USERINTERFACE) =>
        dispatch(setProfileData(data)),
})

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ProfilePage)
)

const WIDTH_SIZE = '542px'

export const TitleSection = styled.h1`
    margin: 0;
    padding: 0;
    margin-bottom: 12px;
    color: ${colors.black.jotaBlack};
    ${fonts.jotaHeading05};
`

export const SubTitle = styled.h2`
    padding: 0;
    margin: 36px 0;
    color: ${colors.black.jotaBlack};
    ${fonts.jotaOverlineLarge};
`

const FormUser = styled.div`
    display: flex;
    flex-direction: column;
    width: ${WIDTH_SIZE};
    margin-bottom: 36px;

    & > div {
        margin-bottom: 24px;
    }

    @media (max-width: ${MOBILE_SIZE}) {
        width: 100%;
    }
`

const ButtonsForm = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 0 !important;

    @media (max-width: ${MOBILE_SIZE}) {
        flex-direction: column;

        & > button:first-child {
            margin-bottom: 24px;
        }

        & > button {
            width: 100% !important;
        }
    }

    & > button {
        width: 50%;
    }

    & > button:first-child {
        margin-right: 18px;
    }
`
const UserData = styled.div``
const UserDataBlock = styled.div`
    &:first-child {
        margin: 36px 0;
    }
`
const UserLabel = styled.div`
    color: ${colors.gray.jotaGrayDarker};
    ${fonts.jotaOverlineSmall};
    margin-bottom: 4px;
`
const UserValue = styled.div`
    color: ${colors.gray.jotaGrayDark};
    ${fonts.jotaBody};
`

const UserPerfil = styled.div`
    padding-bottom: 36px;
    border-bottom: 1px solid ${colors.gray.jotaGrayLighter};
`

const UserPlanos = styled.div``
const UserPlanosList = styled.ul`
    margin-top: 8px;
`
const UserPlanoListItem = styled.li`
    color: ${colors.gray.jotaGrayDark};
    ${fonts.jotaBodyLarge};
`
const DialogForm = styled.div`
    margin-top: 24px;
    margin-bottom: 0 !important;
    display: none;
`

const PasswordNormal = styled.div`
    width: ${WIDTH_SIZE};
`
const PasswordChange = styled.div`
    width: ${WIDTH_SIZE};
`
const InputPass = styled.div`
    margin-bottom: 24px;
`
const TabContainer = styled.div`
    display: inherit;
`
