import {
    AppState,
    NewsletterState as NewsletterState,
} from '../types/newsletter'
import { AnyAction } from 'redux'
import { NewsletterAPI, axiosJOTA, VERTICAL } from '../api'
import { AppDispatchNewsletter } from './../types/newsletter'
import { contentInterface } from './../../pages/newsletter/page-list'
import {
    BACKOFFICE_ERRORS,
    BACKOFFICE_RESPONSES,
} from './../../utils/constants'

const LIMIT_PAGE_RESULT = 10

const initialState: NewsletterState = {
    list: {
        loading: {
            content: true,
            header: true,
        },
        status: null,
        items: [],
        total: 0,
        actualPageNumber: 1,
        actualPage: 1,
        filter: '',
        pageType: 'list',
    },
    categories: {},
    search: {},
    content: {
        loading: true,
        status: null,
        content: {},
        ultimas: {
            loading: true,
            items: [],
            status: null,
        },
    },
}

//
// Reducers - Redux Duck Pattern
//
export default (
    state: NewsletterState = initialState,
    action: AnyAction = { type: '' }
): NewsletterState => {
    switch (action.type) {
        /**
         * LOAD_LIST_START
         * Description: Load list of content - Tributos
         */
        case 'LOAD_LIST_START':
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: {
                        ...state.list.loading,
                        content: true,
                        header: true,
                    },
                },
            }

        /**
         * LOAD_LIST_FINISH
         * Description: Load List - Finish
         */
        case 'LOAD_LIST_FINISH':
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: {
                        ...state.list.loading,
                        content: false,
                        header: false,
                    },
                    items: action.payload.results,
                    total: action.payload.count,
                },
            }

        /**
         * LOAD_LIST_ERROR
         * Description: Load list - Error
         */
        case 'LOAD_LIST_ERROR':
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: {
                        ...state.list.loading,
                        content: false,
                    },
                    filter: '',
                    status: action.payload,
                },
            }

        /**
         * CONTENT_LOAD_START
         * Description: Get content by id - Start
         */
        case 'CONTENT_LOAD_START':
            return {
                ...state,
                list: {
                    ...state.list,
                    status: null,
                },
                content: {
                    loading: true,
                    status: null,
                    content: {},
                    ultimas: {
                        loading: true,
                        items: [],
                        status: null,
                    },
                },
            }

        /**
         * CONTENT_LOAD_FINISH
         * Description: Get content by id - Finish
         */
        case 'CONTENT_LOAD_FINISH':
            return {
                ...state,
                content: {
                    ...state.content,
                    loading: false,
                    content: action.payload,
                },
            }

        /**
         * CONTENT_LOAD_ERROR
         * Description: Get content by id - Error
         */
        case 'CONTENT_LOAD_ERROR':
            return {
                ...state,
                content: {
                    ...state.content,
                    loading: false,
                    status: action.payload,
                },
            }

        /**
         * CONTENT_LOAD_5LAST_START
         * Description: Load last 5 content from Tributos - Start
         */
        case 'CONTENT_LOAD_5LAST_START':
            return {
                ...state,
                content: {
                    ...state.content,
                    ultimas: {
                        ...state.content.ultimas,
                        loading: true,
                    },
                },
            }

        /**
         * CONTENT_LOAD_5LAST_FINISH
         * Description: Load last 5 content from Tributos - Finish
         */
        case 'CONTENT_LOAD_5LAST_FINISH':
            return {
                ...state,
                content: {
                    ...state.content,
                    ultimas: {
                        ...state.content.ultimas,
                        loading: false,
                        items: action.payload,
                    },
                },
            }

        /**
         * CONTENT_LOAD_5LAST_ERROR
         * Description: Load last 5 content from Tributos - Error
         */
        case 'CONTENT_LOAD_5LAST_ERROR':
            return {
                ...state,
                content: {
                    ...state.content,
                    ultimas: {
                        ...state.content.ultimas,
                        loading: false,
                        status: action.payload,
                    },
                },
            }

        /**
         * CONTENT CLEAR
         * Description: Reset content
         */
        case 'CONTENT_CLEAR':
            return {
                ...state,
                content: {
                    ...state.content,
                    loading: true,
                    status: null,
                    content: {},
                    ultimas: {
                        loading: true,
                        items: [],
                        status: null,
                    },
                },
            }

        /**
         * CHANGE_PAGE
         * Description: Control actual page -> pagination
         */
        case 'NEWSLETTER_CHANGE_PAGE':
            return {
                ...state,
                list: {
                    ...state.list,
                    actualPageNumber: action.payload,
                },
            }

        /**
         * STATUS_ERROR
         * Description: Fire a message error on list page - block access to content page
         */
        case 'STATUS_ERROR':
            return {
                ...state,
                list: {
                    ...state.list,
                    status: action.payload,
                },
            }

        /**
         * VERTICAL FILTER
         * Description: Filter vertical on API Request (Tributos, Poder)
         */
        case 'VERTICAL_FILTER':
            return {
                ...state,
                list: {
                    ...state.list,
                    filter: action.payload,
                    actualPageNumber: 1,
                    actualPage: 1,
                },
            }
        default:
            return state
    }
}

//
// Actions - Redux Duck Pattern
//
export type Actions = ReturnType<typeof fetchListStart | typeof fetchListFinish>

/**
 * Fetch List - Start
 * @return {object}
 */
export const fetchListStart = () => ({
    type: 'LOAD_LIST_START',
})

/**
 * Fetch List - Finish
 * @param {array} items
 * @return {object}
 */
export const fetchListFinish = (items: any) => ({
    type: 'LOAD_LIST_FINISH',
    payload: items,
})

/**
 * Fetch List - Error
 * @param {any} err
 * @return {object}
 */
export const fetchListError = (err: any) => ({
    type: 'LOAD_LIST_ERROR',
    payload: err,
})

/**
 * Fetch Content Start
 * @return {object}
 */
export const fetchContentStart = () => ({
    type: 'CONTENT_LOAD_START',
})

/**
 * Fetch List - Finish
 * @param {any} content
 * @return {object}
 */
export const fetchContentFinish = (content: any) => ({
    type: 'CONTENT_LOAD_FINISH',
    payload: content.data,
})

/**
 * Fetch Content - Error
 * @param {any} err
 * @return {object}
 */
export const fetchContentError = (err: any) => ({
    type: 'CONTENT_LOAD_ERROR',
    payload: err,
})

/**
 * Fetch Content - Get last 5 items - Start
 * @return {object}
 */
export const fetchContent5LastStart = () => ({
    type: 'CONTENT_LOAD_5LAST_START',
})

/**
 * Clear content
 * @return {void}
 */
export const clearContent = () => ({
    type: 'CONTENT_CLEAR',
})

/**
 * Fetch Content - Get last 5 items - Finish
 * @param {any} content
 * @return {object}
 */
export const fetchContent5LastFinish = (content: any) => ({
    type: 'CONTENT_LOAD_5LAST_FINISH',
    payload: content,
})

/**
 * Fetch Content - Get last 5 items - Error
 * @param {any} err
 * @return {object}
 */
export const fetchContent5LastError = (err: any) => ({
    type: 'CONTENT_LOAD_5LAST_ERROR',
    payload: err,
})

/**
 * Fetch Change Page
 * @param {number} page
 * @return {void}
 */
export const fetchChangePage = (page: number) => ({
    type: 'NEWSLETTER_CHANGE_PAGE',
    payload: page,
})

/**
 * Action Error Status
 * @param {string} msg
 * @return {void}
 */
export const actionErrorStatus = (msg: string) => ({
    type: 'STATUS_ERROR',
    payload: msg,
})

/**
 * Filter Vertical Action
 * @param {string} vertical
 * @return {void}
 */
export const changeFilterVertical = (vertical: string) => ({
    type: 'VERTICAL_FILTER',
    payload: vertical,
})

//
// Async Opetarions - Redux Duck Pattern
//

/**
 * Tributos
 * Get List from home
 * @param {number} page
 * @param {type} type - List or Categories
 * @param {number} id - optional - categories only
 * @param {number | null} vertical - optional - vertical type
 * @param {string} query - optional - search only
 * @param {any} roles
 * @param {string} tagValue
 * @return {array} items
 */
export const getList = (
    page: number = 1,
    type: contentInterface = 'list',
    id: number = 0,
    vertical: string | null = null,
    query: string = '',
    roles: any = {},
    tagValue: string = ''
) => {
    return async (
        dispatch: AppDispatchNewsletter,
        _getState: () => AppState
    ) => {
        try {
            dispatch(fetchListStart())

            /**
             * Fetch API
             */
            const API_BO = getAPIByType(
                page,
                type,
                id,
                query,
                getNewsletterByType(vertical),
                tagValue
            )

            if (API_BO) {
                const items = await (await axiosJOTA.get(API_BO)).data

                /**
                 * Filter Results by Role
                 */
                items.results = filterContentByRole(roles, items.results)
                dispatch(fetchListFinish(items))
            } else {
                throw new Error()
            }
        } catch (err: any) {
            if (err.message === BACKOFFICE_ERRORS.NETWORK_ERROR) {
                dispatch(fetchListError(BACKOFFICE_RESPONSES.NETWORK_ERROR))
            } else if (err.message === BACKOFFICE_ERRORS.REQUEST_FAILED) {
                dispatch(fetchListError(BACKOFFICE_RESPONSES.REQUEST_FAILED))
            } else {
                dispatch(fetchListError(BACKOFFICE_RESPONSES.GENERAL))
            }
        }
    }
}

/**
 * Given a string, return the vertical type
 * @param {string} value - string
 * @return {VERTICAL} A function that returns a vertical based on the value of the newsletter type.
 */
const getNewsletterByType = (value: string | null) => {
    switch (value) {
        case 'poder':
            return VERTICAL.PODER
        case 'tributos':
            return VERTICAL.TRIBUTOS
        default:
            return null
    }
}

/**
 * Get API by Type
 * Description: Get API List between normal list or categories
 * @param {number} page
 * @param {string} type
 * @param {number} id
 * @param {string} query
 * @param {number | null} vertical
 * @param {tag} tag
 * @param {number} quantity
 * @return {string}
 */
export const getAPIByType = (
    page: number,
    type: contentInterface,
    id: number,
    query: string,
    vertical: number | null,
    tag?: string,
    quantity?: number
) => {
    switch (type) {
        case 'list':
            return NewsletterAPI.list(
                page,
                quantity ?? LIMIT_PAGE_RESULT,
                vertical
            )
        case 'categories':
            return NewsletterAPI.categories(
                page,
                quantity ?? LIMIT_PAGE_RESULT,
                id
            )
        case 'search':
            return NewsletterAPI.search(page, query)
        case 'tag':
            if (tag) {
                return NewsletterAPI.tag(
                    page,
                    tag,
                    quantity ?? LIMIT_PAGE_RESULT
                )
            }
            break
        default:
            return NewsletterAPI.list(page, quantity ?? LIMIT_PAGE_RESULT)
    }
}

/**
 * Tributos
 * Get a specific content by id
 * @param {number} id
 * @return {object}
 */
export const getContent = (id: number) => {
    return async (
        dispatch: AppDispatchNewsletter,
        _getState: () => AppState
    ) => {
        try {
            dispatch(fetchContentStart())
            const content = await axiosJOTA.get(`${NewsletterAPI.content(id)}`)
            dispatch(fetchContentFinish(content))
        } catch (err: any) {
            dispatch(fetchContentError('error'))
        }
    }
}

/**
 * Tributos
 * Return last 5 items
 * @return {object}
 */
export const getContent5Last = () => {
    return async (
        dispatch: AppDispatchNewsletter,
        _getState: () => AppState
    ) => {
        try {
            dispatch(fetchContent5LastStart())
            const items = await (
                await axiosJOTA.get(`${NewsletterAPI.list(1, 5)}`)
            ).data.results
            dispatch(fetchContent5LastFinish(items))
        } catch (err) {
            dispatch(fetchContent5LastError('error'))
        }
    }
}

/**
 * Clear Content
 * @return {void}
 */
export const clearContentStore = () => {
    return async (
        dispatch: AppDispatchNewsletter,
        _getState: () => AppState
    ) => {
        dispatch(clearContent())
    }
}

/**
 * Change Page
 * @param {number} page
 * @return {void}
 */
export const changePageNumber = (page: number) => {
    return async (
        dispatch: AppDispatchNewsletter,
        _getState: () => AppState
    ) => {
        dispatch(fetchChangePage(page))
    }
}

/**
 * Filter Content by User Role
 * @param {any} userRole
 * @param {any[]} items
 * @return {any[]} items
 */
export const filterContentByRole = (userRole: any, items: any[]) => {
    // userRole is empty?
    if (Object.keys(userRole).length === 0) return items

    return (
        items &&
        items.length > 0 &&
        items.filter((item) => {
            const verticalTemp = `has_${item.category.vertical.name.toLowerCase()}`
            return userRole[verticalTemp] && item
        })
    )
}

/**
 * Given a user role and a route, return true if the user has the role for that route
 * @param {any} userRole - The user's role object.
 * @param {string} route - The route that the user is trying to access.
 * @return {boolean} A boolean value.
 */
export const filterUserByRole = (userRole: any, route: string) => {
    if (route) {
        const roleTemp = `has_${route.toLowerCase()}`
        return userRole[roleTemp] ? true : false
    }

    return true
}

/**
 * It sets the error status in the store.
 * @param {string} msg - string
 * @return {Function} A function that dispatches an action.
 */
export const setErrorStatus = (msg: string) => {
    return async (
        dispatch: AppDispatchNewsletter,
        _getState: () => AppState
    ) => {
        dispatch(actionErrorStatus(msg))
    }
}

/**
 * Filter Vertical Props
 * @param {string} vertical
 * @return {Function}
 */
export const filterVerticalProps = (vertical: string) => {
    return async (
        dispatch: AppDispatchNewsletter,
        _getState: () => AppState
    ) => {
        dispatch(changeFilterVertical(vertical))
    }
}
