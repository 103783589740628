import React, { FC } from 'react'
import MinusIcon from './../static/icons/minus.svg'
import CheckIcon from './../static/icons/check.svg'

interface PassInterface {
    passwordRules: {
        hasUpper: boolean | null
        hasLower: boolean | null
        hasNumber: boolean | null
        has8Chars: boolean | null
        hasPasswordRewrite: boolean | null
    }
    setPasswordRules: any
}

/**
 * Password Requirements
 * @param {PassInterface} passwordRules
 * @param {any} setPasswordRules
 * @return {ReactElement}
 */
const PasswordRequirements: FC<PassInterface> = ({
    passwordRules,
    setPasswordRules,
}) => (
    <div className='reset-password-requirements-container'>
        <div className='reset-password-line-one'>
            <div className={passwordRules.has8Chars ? 'checked' : 'unchecked'}>
                <img
                    src={passwordRules.has8Chars ? CheckIcon : MinusIcon}
                    alt='icon'
                    width='24'
                    height='24'
                />
                <span>Pelo menos 8 caracteres</span>
            </div>
            <div className={passwordRules.hasLower ? 'checked' : 'unchecked'}>
                <img
                    src={passwordRules.hasLower ? CheckIcon : MinusIcon}
                    alt='icon'
                    width='24'
                    height='24'
                />
                <span>Uma letra minúscula</span>
            </div>
        </div>
        <span />
        <div className='reset-password-line-two'>
            <div className={passwordRules.hasUpper ? 'checked' : 'unchecked'}>
                <img
                    src={passwordRules.hasUpper ? CheckIcon : MinusIcon}
                    alt='icon'
                    width='24'
                    height='24'
                />
                <span>Uma letra maiúscula</span>
            </div>
            <div className={passwordRules.hasNumber ? 'checked' : 'unchecked'}>
                <img
                    src={passwordRules.hasNumber ? CheckIcon : MinusIcon}
                    alt='icon'
                    width='24'
                    height='24'
                />
                <span style={{ marginRight: '63px' }}>Um número</span>
            </div>
        </div>
    </div>
)

export default PasswordRequirements
