import React, { useEffect } from 'react'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { URLS } from './../../utils/constants'
import { Auth } from 'aws-amplify'

const SSO = (props: any) => {
    const history = useHistory()

    useEffect(() => {
        const params = queryString.parse(location.search) as any

        /**
         * Chek if this param data is valid
         * Yes: Write localstore variables for cognito
         * No: Redirect to login page
         */
        if (
            !params.access_token ||
            !params.id ||
            !params.email ||
            !params.given_name ||
            !params.family ||
            !params.username ||
            !params.teste ||
            !params.client_id
        )
            history.push(URLS.login)

        Auth.currentSession().catch((error) => {
            localStorage.setItem(
                `CognitoIdentityServiceProvider.${params.client_id}.${params.email}.idToken`,
                params.id
            )
            localStorage.setItem(
                `CognitoIdentityServiceProvider.${params.client_id}.${params.email}.accessToken`,
                params.access_token
            )
            localStorage.setItem(
                `CognitoIdentityServiceProvider.${params.client_id}.LastAuthUser`,
                params.email
            )
            localStorage.setItem(
                `CognitoIdentityServiceProvider.${params.client_id}.${params.email}.clockDrift`,
                '1'
            )
            localStorage.setItem(
                `CognitoIdentityServiceProvider.${params.client_id}.${params.email}.userData`,
                `{"UserAttributes":[{"Name":"sub","Value":${params.username}},{"Name":"email_verified","Value":"true"},{"Name":"name","Value": ${params.email}},{"Name":"given_name","Value":${params.name}},{"Name":"family_name","Value": ${params.family}},{"Name":"email","Value":${params.email}}],"Username":${params.username}}`
            )
            localStorage.setItem(
                `CognitoIdentityServiceProvider.${params.client_id}.${params.email}.refreshToken`,
                params.refresh_token
            )
            window.location.assign(
                `/?access_token=${params.access_token}&id_token=${params.id}&token_type=Bearer&expires_in=3600&redirect_url_after_login=${params.redirect_url}`
            )
        })
    }, [])

    return <h1>AGUARDE</h1>
}

export default SSO
