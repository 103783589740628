export const URLS = {
    login: '/login',
    esqueci: '/forgot-password',
    reset: '/reset-password',
    logout: '/logout',
    home: '/home',
    loginSSO: '/sso',
    root: '/',
    profile: '/minha-conta',
    newsletter: {
        list: '/:newsletter',
        search: '/pesquisar',
        categoria: '/:newsletter/categoria/:id',
        conteudo: '/:newsletter/:id/:search?',
        tag: '/tag/:tag',
        conteudoSet: (
            vertical: string,
            id: number,
            search: boolean = false
        ) => {
            if (search) {
                return `/${vertical}/${id}/busca`
            } else {
                return `/${vertical}/${id}`
            }
        },
        categoriaSet: (vertical: string, id: number) =>
            `/${vertical}/categoria/${id}`,
        searchSet: (s: string) => `/pesquisar?q=${s}`,
        tagSet: (tag: string) => `/tag/${tag}`,
    },
}

/**
 * COGNITO_WARNINGS
 * Description: Warnings from Cognito
 */
export const COGNITO_WARNINGS = {
    PASS_EXCEEDED: 'Password attempts exceeded',
    INCORRECT: 'Incorrect username or password.',
    PRE_TOKEN: 'PreTokenGeneration failed with error Unexpected error.',
    NOT_LOGGED: 'NOT_LOGGED',
    LIMIT_EXCEEDED: 'LimitExceededException',
    EXPIRED_CODE: 'ExpiredCodeException',
    FORGOT_PASSWORD_RESET: 'forgotPasswordReset',
    USERNAME_EMPTY: 'Username cannot be empty',
}

/**
 * COGNITO RESPONSES
 * Description: Cognito responses from an api
 */
export const COGNITO_RESPONSES = {
    PASS_EXCEEDED:
        'Tentativas de senha excedidas. Por favor aguarde alguns minutos',
    INCORRECT: 'E-mail ou senha incorretos. Tente novamente.',
    PRE_TOKEN:
        'Houve um erro, entre em contato com nossa equipe pelo email contato@jota.info e informe o código BKOFF',
    NOT_LOGGED: 'Usuário não autenticado. Por favor efetue o login.',
    LIMIT_EXCEEDED:
        'Limite de tentativas excedido, tente depois de algum tempo.',
    EXPIRED_CODE: 'Código inválido fornecido, solicite um código novamente.',
    PASSWORD_CHANGE:
        'Sua senha foi alterada com sucesso. Clique no botão abaixo para realizar o login.',
    ROLE_ERROR:
        'Você ainda não possui uma conta JOTA PRO. Solicite uma demonstração para sua empresa ou volte para o nosso site.',
    WRONG_PASS: 'Senha inválida',
    INPUT_ACTUAL_PASS_WRONG: 'A senha informada está incorreta',
    INPUT_ACTUAL_PASS_EXCEEDED:
        'Número de tentativas excedidas. Por favor aguarde alguns minutos',
    INPUT_PASS_NOT_EQUAL: 'As senhas não coincidem',
    INPUT_NOT_EMPTY: 'O campo não pode ficar vazio',
    PASS_SUCCESS: 'Sua senha foi alterada com sucesso.',
    USERNAME_EMPTY: 'Ocorreu um erro, tente novamente. COD: USER_EMPTY',
    PASS_MINIMUM: 'Digite pelo menos 8 caracteres',
}

/**
 * BACKOFFICE ERRORS
 */
export const BACKOFFICE_ERRORS = {
    REQUEST_FAILED: 'Request failed with status code 400',
    NETWORK_ERROR: 'Network Error',
}

/**
 * BACKOFFICE RESPONSES
 */
export const BACKOFFICE_RESPONSES = {
    NO_ACCESS:
        'Você ainda não possui acesso à plataforma JOTA PRO. \n Solicite uma demonstração para a empresa ou volte para o nosso site.',
    NO_API: 'Ocorreu um erro. Tente novamente ou contate o suporte do JOTA PRO',
    NO_ACCESS_VERTICAL: 'Ops, você não tem acesso a esse conteúdo.',
    NO_CATEGORY_ACCESS:
        'Ops! Não encontramos nenhum conteúdo ou você não tem acesso a essa categoria.',
    NETWORK_ERROR:
        'Ops, estamos com um problema. Por favor tente novamente ou contate o suporte. ERROR: NO_BKOFF',
    REQUEST_FAILED:
        'Ops, estamos com um problema. Por favor tente novamente ou contate o suporte. ERROR: NO_REQUEST',
    GENERAL:
        'Ops, estamos com um problema. Por favor tente novamente ou contate o suporte. ERROR: GENERAL',
}

export const JOTA = {
    PRO: '//jota.info/pro',
    INFO: '//jota.info',
}

export const NORMAL = 'normal'
export const ERROR = 'error'
export const PASS_MINIMUM = 8

export const MODAL = {
    text: 'Aqui você terá acesso aos seus conteúdos PRO. Mesma qualidade de sempre, mais segurança e melhor usabilidade.',
    title: 'ESSA É A PLATAFORMA JOTA PRO!',
    link: 'https://www.jota.info/area/plataforma-jota-pro#p-350580',
}

export const SEARCH = {
    header: 'Resultados da Busca',
    count: (value: number, term: string) =>
        `Foram encontradas <strong>${value}</strong> ocorrências  para o termo <span>${term}</span>.`,
    notFound: `Não encontramos nenhum resultado para o termo buscado. <br />Tente pesquisar outro termo ou`,
    return: 'volte para a página inicial.',
}

export const TAGS = {
    filterBy: 'Exibir',
}
