/* eslint-disable no-unused-vars */
import axios from 'axios'

// Axios Config

axios.defaults.baseURL = process.env.REACT_APP_URL_BACKOFFICE_API
axios.defaults.headers.post['Content-Type'] = 'application/json'

export const axiosJOTA = axios.create({
    baseURL: process.env.REACT_APP_URL_BACKOFFICE_API,
})

/**
 * Tributos
 * API - List, Search, Categories and Content
 */
export const NewsletterAPI = {
    listCategories: () =>
        `${process.env.REACT_APP_URL_BACKOFFICE_API}/newsletters/campaigns/categories/?vertical=1`,
    list: (page: number, limit: number, vertical?: number | null) => {
        if (vertical) {
            return `${process.env.REACT_APP_URL_BACKOFFICE_API}/newsletters/campaigns/latest/?page=${page}&limit=${limit}&vertical=${vertical}`
        } else {
            return `${process.env.REACT_APP_URL_BACKOFFICE_API}/newsletters/campaigns/latest/?page=${page}&limit=${limit}`
        }
    },
    categories: (page: number, limit: number, id: number) =>
        `${process.env.REACT_APP_URL_BACKOFFICE_API}/newsletters/campaigns/latest/?category=${id}&page=${page}&limit=${limit}`,
    content: (id: number) =>
        `${process.env.REACT_APP_URL_BACKOFFICE_API}/newsletters/campaigns/${id}/`,
    search: (page: number, query: string, limit: number = 10) =>
        `${process.env.REACT_APP_URL_BACKOFFICE_API}/newsletters/campaigns/latest/?search=${query}&page=${page}&limit=${limit}`,
    tag: (page: number, tag: string, quantity: number) =>
        `${process.env.REACT_APP_URL_BACKOFFICE_API}//newsletters/campaigns/latest?tags=${tag}&page=${page}&limit=${quantity}`,
}

/**
 * Profile User API
 * Me - Return data from the user
 */
export const ProfileAPI = {
    me: () =>
        `${process.env.REACT_APP_URL_BACKOFFICE_API}/api/auth/users/profiles/me/`,
    resetPassword: (email: string) =>
        `${process.env.REACT_APP_BO_API}/auth/users/forgot-password?email=${email}&context=pro`,
}

/**
 * Menu API
 */
export const MenuAPI = {
    get: () => `${process.env.REACT_APP_URL_BACKOFFICE_API}/newsletters/menu/`,
}

/**
 * enum Vertical
 * Tributos = 1
 * Poder = 2
 */
export enum VERTICAL {
    TRIBUTOS = 1,
    PODER = 2,
}
