import React, { FC } from 'react'
import styled from 'styled-components'

type WaitProps = {
    width: string
    height: string
    color?: string
    style?: any
}

const Wait: FC<WaitProps> = ({
    width,
    height,
    color = 'rgba(230, 232, 237, 0.4)',
    style,
}) => {
    return (
        <Container width={width} height={height} color={color} style={style} />
    )
}

export default Wait

const Container = styled.div<{ width: string; height: string; color: string }>`
    background: ${(props) => props.color};
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    display: block;
    box-sizing: border-box;
`
