import React, { FC } from 'react'
import { Tag } from '@JOTAJornalismo/jota-design-system'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { filterVerticalProps } from './../flux/ducks/newsletter'
import { TAGS } from './../utils/constants'
import { colors, fonts, MOBILE_SIZE } from './../static/theme'

export type VerticalI = {
    poder: boolean
    tributos: boolean
}

type VerticalFilterI = {
    roles: any
    filter: string
    filterVerticalTemp: Function
}

const VerticalFilter: FC<VerticalFilterI> = ({
    roles,
    filter,
    filterVerticalTemp,
}) => {
    /**
     * Check if this click will be both unavailable
     * @param {string} type
     * @return {void}
     */
    const checkEmpty = (type: string) => {
        if (type === filter) {
            return true
        }

        return false
    }

    return (
        <VerticalContainer>
            <LabelText>{TAGS.filterBy}</LabelText>
            <TagContainer>
                {roles.has_poder && (
                    <Tag
                        label='Poder'
                        onClick={() => {
                            checkEmpty('poder')

                            if (filter === 'poder') {
                                filterVerticalTemp('poder')
                            } else if (filter === '') {
                                filterVerticalTemp('tributos')
                            } else {
                                filterVerticalTemp('')
                            }

                            if (checkEmpty('poder')) {
                                filterVerticalTemp('')
                            }
                        }}
                        size='large'
                        type={
                            filter === 'poder' || filter === ''
                                ? 'filled'
                                : 'outline'
                        }
                        color='blue'
                    ></Tag>
                )}
                {roles.has_tributos && (
                    <Tag
                        label='Tributos'
                        onClick={() => {
                            checkEmpty('tributos')

                            if (filter === 'tributos') {
                                filterVerticalTemp('tributos')
                            } else if (filter === '') {
                                filterVerticalTemp('poder')
                            } else {
                                filterVerticalTemp('')
                            }

                            if (checkEmpty('tributos')) {
                                filterVerticalTemp('')
                            }
                        }}
                        size='large'
                        type={
                            filter === 'tributos' || filter === ''
                                ? 'filled'
                                : 'outline'
                        }
                        color='green'
                    ></Tag>
                )}
            </TagContainer>
        </VerticalContainer>
    )
}

const mapStateToProps = (state: any) => ({
    roles: state.auth.roles,
    filter: state.newsletters.list.filter,
})

const mapDispatchToProps = (dispatch: any) => ({
    filterVerticalTemp: (vertical: string) =>
        dispatch(filterVerticalProps(vertical)),
})

export default connect(mapStateToProps, mapDispatchToProps)(VerticalFilter)

const VerticalContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: ${MOBILE_SIZE}) {
        margin-top: 12px;
    }
`

const TagContainer = styled.div`
    display: flex;
    margin-left: 8px;
    & > div:first-child {
        margin-right: 8px;
    }
`

const LabelText = styled.div`
    ${fonts.jotaOverlineXSmall()};
    color: ${colors.gray.jotaGrayDark};
`
