import React, { FC, useEffect } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { AppDispatch, AppState } from './../flux/types/auth'
import { signOut } from './../flux/ducks/auth'
import { useHistory } from 'react-router-dom'
import { URLS } from './../utils/constants'
import hotjarStart from './../utils/hotjarStart'

type Props = {
    user: any
    roles: any
    signOutState: () => void
}

const PaginaProtegida: FC<Props> = ({ user, signOutState, roles }) => {
    const history = useHistory()

    /**
     * Roles
     * Description:
     * Check if this user has specific roles for access
     * Actual:
     * - Tributos (has_tributos)
     */
    useEffect(() => {
        // Tributos (TA INVERTIDO)
        if (
            roles && // 👈 null and undefined check
            Object.keys(roles).length !== 0 &&
            Object.getPrototypeOf(roles) === Object.prototype &&
            roles.has_tributos !== true
        ) {
            signOutState()
            history.push(`${URLS.login}?erro=tributos`)
        }
    }, [roles])

    useEffect(() => {
        // Hotjar
        if (user && user.attributes && user.attributes.email) {
            hotjarStart(
                {
                    email: user.attributes.email,
                    name: user.attributes.name,
                    roles: roles,
                },
                'enter-home',
                '/home'
            )
        }
    }, [])

    return (
        <div
            style={{
                margin: '2em',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '600px',
                }}
            >
                <Paragraph
                    style={{
                        background: 'red',
                        color: 'white',
                        padding: '1em',
                        marginBottom: '1em',
                    }}
                >
                    HOTJAR:
                    <strong>{process.env.REACT_APP_HOTJAR_CLIENT_ID}</strong>
                </Paragraph>
                <Paragraph>
                    Name 2:{' '}
                    <strong>
                        {user && user.attributes && user.attributes.given_name}
                    </strong>
                </Paragraph>
                <Paragraph>
                    Family Name 2:{' '}
                    <strong>
                        {user && user.attributes && user.attributes.family_name}
                    </strong>
                </Paragraph>
                <Paragraph>
                    Email 2:{' '}
                    <strong>
                        {user && user.attributes && user.attributes.email}
                    </strong>
                </Paragraph>
                <hr />
                <br />
                <strong>ACCESS TOKEN 2</strong>
                <CodePage>
                    {user &&
                        user.signInUserSession &&
                        user.signInUserSession.accessToken &&
                        user.signInUserSession.accessToken.jwtToken}
                </CodePage>
                <hr />
                <br />
                <strong>ID TOKEN 2</strong>
                <CodePage>
                    {user &&
                        user.signInUserSession &&
                        user.signInUserSession.idToken &&
                        user.signInUserSession.idToken.jwtToken}
                </CodePage>

                <br />
                <hr />
                <strong>REFRESH TOKEN 2</strong>
                <CodePage>
                    {user &&
                        user.signInUserSession &&
                        user.signInUserSession.refreshToken &&
                        user.signInUserSession.refreshToken.token}
                </CodePage>

                <br />
                {/* <strong>USER ATTRIBUTES</strong> */}
                {/* <CodePage>{JSON.stringify(user.attributes)}</CodePage> */}

                <br />
                <strong>USER 2</strong>
                <CodePage>{JSON.stringify(user)}</CodePage>
            </div>
            <div style={{ height: '100px' }}>
                <ButtonPage
                    onClick={() => {
                        const LOGIN_URL = `${process.env.REACT_APP_LOGIN_URL}/login`
                        const HOST = process.env.REACT_APP_LOGIN_URL
                        const EMAIL = user.attributes.email
                        const NAME = user.attributes.given_name
                        const FAMILY = user.attributes.family_name
                        const USERNAME = user.attributes.sub
                        const CLIENT_ID =
                            process.env
                                .REACT_APP_AWS_COGNITO_USER_POOL_CLIENT_ID
                        const URL = `${LOGIN_URL}?access_token=${user.signInUserSession.accessToken.jwtToken}&id=${user.signInUserSession.idToken.jwtToken}&token_type=Bearer&expires_in=3600&redirect_url=${HOST}&refresh_token=${user.signInUserSession.refreshToken.token}&email=${EMAIL}&given_name=${NAME}&family=${FAMILY}&username=${USERNAME}&client_id=${CLIENT_ID}`

                        window.open(URL)
                    }}
                >
                    <div>
                        <strong>WORDPRESS</strong>
                    </div>
                    <div>Será direcionado para o JOTA.INFO (next)</div>
                </ButtonPage>
                <ButtonPage
                    onClick={() => {
                        signOut()
                    }}
                >
                    LOGOUT
                </ButtonPage>
            </div>
        </div>
    )
}

export const mapStateToProps = (state: AppState) => ({
    user: state.auth.user,
    authState: state.auth.authState,
    roles: state.auth.roles,
})

export const mapDispatchToProps = (dispatch: AppDispatch) => ({
    signOutState: () => dispatch(signOut()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PaginaProtegida)

const ButtonPage = styled.button`
    padding: 1em;
    margin: 1em;
    border: none;
    background: blue;
    color: white;
`

const CodePage = styled.code`
    display: inline-block;
    flex-wrap: inherit;
    overflow-wrap: anywhere;
    padding: 1em;
    background: #f3f2f2;
    margin: 2em;
    max-height: 50px;
    overflow: auto;
`

const Paragraph = styled.p`
    margin: 0;
`
