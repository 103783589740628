import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import styled from 'styled-components'
import 'react-loading-skeleton/dist/skeleton.css'
import { MOBILE_SIZE } from '../../static/theme'

const NewslettersList = () => (
    <SkeletonTheme baseColor='#f9f9f9' highlightColor='#e1e1e1'>
        <LoadingGrid>
            <SkeletonItemList />
            <SkeletonItemList />
            <SkeletonItemList />
            <SkeletonItemList />
            <SkeletonItemList />
            <SkeletonItemList />
            <SkeletonItemList />
            <SkeletonItemList />
            <SkeletonItemList />
            <SkeletonItemList />
        </LoadingGrid>
    </SkeletonTheme>
)

export const LoadingHeader = () => (
    <SkeletonTheme baseColor='#f9f9f9' highlightColor='#e1e1e1'>
        <LoadingRow>
            <SkeletonOverride
                height='10px'
                width='150px'
                widthMobile='20%'
                style={{ margin: '2px' }}
                count={1}
            />
            <SkeletonOverride
                height='40px'
                width='300px'
                widthMobile='20%'
                style={{ margin: '2px' }}
                count={1}
            />
        </LoadingRow>
    </SkeletonTheme>
)

const SkeletonItemList = () => (
    <SkeletonRoot>
        <SkeletonOverride
            height='15px'
            width='100px'
            widthMobile='20%'
            style={{ margin: '2px' }}
            count={1}
        />
        <SkeletonOverride
            height='25px'
            width='450px'
            widthMobile='90%'
            style={{ margin: '2px' }}
            count={2}
        />
        <SkeletonOverride
            height='15px'
            width='250px'
            widthMobile='40%'
            style={{ margin: '2px' }}
            count={1}
        />
    </SkeletonRoot>
)

export default NewslettersList

const LoadingGrid = styled.div`
    display: grid;
    grid-template-areas: 'column1 column2';
    grid-gap: 36px;
    box-sizing: border-box;
    margin-top: 36px;

    @media (max-width: ${MOBILE_SIZE}) {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
`

const SkeletonOverride = styled(Skeleton)<{
    width: string
    height: string
    widthMobile: string
}>`
    width: ${(props) => props.width} !important;
    height: ${(props) => props.height} !important;

    @media (max-width: ${MOBILE_SIZE}) {
        width: ${(props) => props.widthMobile} !important;
    }
`

const SkeletonRoot = styled.div`
    width: 90%;
    @media (max-width ${MOBILE_SIZE}) {
        width: 100%;
    }
`

const LoadingRow = styled.div`
    display: flex;
    flex-direction: column;
`
