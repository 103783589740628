import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import styled from 'styled-components'
import 'react-loading-skeleton/dist/skeleton.css'
import { MOBILE_SIZE } from '../../static/theme'

export const NewslettersContentLoading = () => (
    <SkeletonTheme baseColor='#f9f9f9' highlightColor='#e1e1e1'>
        <LoadingGrid>
            <Column>
                <SkeletonOverride
                    height='15px'
                    width='100px'
                    widthMobile='30%'
                    style={{ margin: '2px', marginBottom: '10px' }}
                    count={1}
                />
                <Row>
                    <SkeletonOverride
                        height='36px'
                        width='36px'
                        widthMobile='30%'
                        style={{ margin: '2px' }}
                        count={1}
                    />
                    <SkeletonOverride
                        height='36px'
                        width='100px'
                        widthMobile='30%'
                        style={{ margin: '2px' }}
                        count={1}
                    />
                </Row>
                <Line />
                <SkeletonOverride
                    height='40px'
                    width='550px'
                    widthMobile='30%'
                    style={{ margin: '2px', marginTop: '36px' }}
                    count={1}
                />
                <SkeletonOverride
                    height='40px'
                    width='350px'
                    widthMobile='30%'
                    style={{ margin: '2px' }}
                    count={1}
                />
                <SkeletonOverride
                    height='15px'
                    width='250px'
                    widthMobile='30%'
                    style={{ margin: '2px', marginTop: '20px' }}
                    count={1}
                />
                <br />
                <br />
                {skeletonLoadingSame()}
                <br />
                {skeletonLoadingSame()}
                <br />
                {skeletonLoadingSame()}
                <br />
                {skeletonLoadingSame()}
            </Column>
        </LoadingGrid>
    </SkeletonTheme>
)

export const NewslettersContent5LastLoading = () => (
    <SkeletonTheme baseColor='#f9f9f9' highlightColor='#e1e1e1'>
        <LoadingGrid>
            <Column>
                <SkeletonItemList />
                <SkeletonItemList />
                <SkeletonItemList />
                <SkeletonItemList />
                <SkeletonItemList />
            </Column>
        </LoadingGrid>
    </SkeletonTheme>
)

export const LastFourNewsLoading = () => (
    <SkeletonTheme baseColor='#f9f9f9' highlightColor='#e1e1e1'>
        <LoadingGrid>
            <Column>
                <SkeletonItemList />
                <SkeletonItemList />
            </Column>
            <Column>
                <SkeletonItemList />
                <SkeletonItemList />
            </Column>
        </LoadingGrid>
    </SkeletonTheme>
)

const SkeletonItemList = () => (
    <div style={{ marginBottom: '36px' }}>
        <SkeletonOverride
            height='15px'
            width='100px'
            widthMobile='30%'
            style={{ margin: '2px' }}
            count={1}
        />
        <SkeletonOverride
            height='25px'
            width='330px'
            widthMobile='100%'
            style={{ margin: '2px' }}
            count={2}
        />
        <SkeletonOverride
            height='15px'
            width='250px'
            widthMobile='50%'
            style={{ margin: '2px' }}
            count={1}
        />
    </div>
)

const LoadingGrid = styled.div`
    display: grid;
    grid-template-areas: 'column1 column2';
    grid-gap: 36px;
    box-sizing: border-box;

    @media (max-width: ${MOBILE_SIZE}) {
        display: flex;
        flex-direction: column;
    }
`

const SkeletonOverride = styled(Skeleton)<{
    width: string
    height: string
    widthMobile: string
}>`
    width: ${(props) => props.width} !important;
    height: ${(props) => props.height} !important;

    @media (max-width: ${MOBILE_SIZE}px) {
        width: ${(props) => props.widthMobile} !important;
    }
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
`

const Line = styled.div`
    margin-top: 12px;
    width: 100%;
    border-bottom: 1px solid #e6e8ed;
`

/**
 * Skeleton Loading - return same block
 * @return {React.Element}
 */
const skeletonLoadingSame = () => (
    <SkeletonOverride
        height='15px'
        width='450px'
        widthMobile='30%'
        style={{ margin: '2px', marginTop: '0px' }}
        count={6}
    />
)
