import React, { FC, ReactElement } from 'react'
import { Breadcrumb, CategoryName } from './../pages/newsletter/components'
import { Name } from './../pages/newsletter/components/categoryName'
import { colors } from './../static/theme'
import { SEARCH, URLS } from './../utils/constants'
import { colorByVertical } from './../utils/helpers'
import styled from 'styled-components'
import { HeaderPageTXT } from './page-structure'

/**
 * Header Categories
 * @param {string} data
 * @return {void}
 */
export const HeaderCategories = (data: any) => {
    return (
        <CategorieHeader>
            <Breadcrumb
                fromSearch={false}
                items={[
                    { name: 'Home', route: URLS.root },
                    {
                        name: data.categoryName,
                        route: URLS.newsletter.categoriaSet(
                            data.categoryName &&
                                data.categoryName.toLowerCase(),
                            data.categoryId
                        ),
                    },
                ]}
            />
            <CategoryName
                name={data.categoryName}
                icon={data.categoryIcon}
                vertical={data.vertical}
                color={colorByVertical(data.vertical.toLowerCase())}
            />
        </CategorieHeader>
    )
}

/**
 * Header Tag
 * @param {any} tag
 * @return {React.Element}
 */
export const HeaderTag = (tag: any) => (
    <CategorieHeader>
        <Breadcrumb
            fromSearch={false}
            items={[
                { name: 'Home', route: URLS.root },
                { name: `#${tag.tag}`, route: URLS.newsletter.tagSet(tag.tag) },
            ]}
        />
        <Name reset={true}>#{tag.tag}</Name>
    </CategorieHeader>
)

/**
 * Header Search
 * @param {any} isSearch
 * @param {any} list
 * @return {ReactElement}
 */
export const HeaderSearch: FC<{ isSearch: any; list: any }> = ({
    isSearch,
    list,
}): ReactElement => {
    /**
     * Search Header
     */
    if (list.total > 0) {
        return (
            <SearchHeader>
                <HeaderPageTXT>{SEARCH.header}</HeaderPageTXT>
                <SearchCount
                    dangerouslySetInnerHTML={{
                        __html: SEARCH.count(
                            list && list.total,
                            isSearch.query
                        ),
                    }}
                />
            </SearchHeader>
        )
    } else {
        return (
            <SearchHeader>
                <HeaderPageTXT>{SEARCH.header}</HeaderPageTXT>
                <SearchCount
                    dangerouslySetInnerHTML={{ __html: SEARCH.notFound }}
                />

                <SearchCount>
                    <a href={URLS.newsletter.list}>{SEARCH.return}</a>
                </SearchCount>
            </SearchHeader>
        )
    }
}

const CategorieHeader = styled.div`
    display: flex;
    flex-direction: column;
`
const SearchHeader = styled.div`
    margin-top: 0;
`
const SearchCount = styled.div`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 36px;
    color: ${colors.gray.jotaGrayDark};

    strong {
        color: ${colors.black.jotaBlack};
        font-weight: 700;
    }

    span {
        color: ${colors.orange.jotaOrange};
        font-weight: 700;

        &::before {
            content: '"';
        }

        &::after {
            content: '"';
        }
    }

    a {
        color: ${colors.green.jotaGreen};
    }
`
