import React, { FC, useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { colors } from '../../static/theme'
import { connect } from 'react-redux'
import { Card, Dialog, Pagination } from '@JOTAJornalismo/jota-design-system'
import { BACKOFFICE_RESPONSES, MODAL, URLS } from '../../utils/constants'
import { NewslettersListLoading } from '../../utils/loading'
import {
    colorByVertical,
    colorIconVertical,
    dateConvert,
    getPageType,
    getUserDataMixPanel,
} from '../../utils/helpers'
import { mixPanelSetData } from '../../utils/mixpanel'
import { useHistory } from 'react-router-dom'
import hotjarStart from '../../utils/hotjarStart'
import { Header, MenuComponent, Modal, VerticalFilter } from '../../components'
import queryString from 'query-string'
import {
    changePageNumber,
    clearContentStore,
    filterUserByRole,
    getList,
} from '../../flux/ducks/newsletter'
import { changeAvatarMenuOpen, changePage } from '../../flux/ducks/menu'

import {
    Column,
    Container,
    Content,
    HeaderPage,
    HeaderPageList,
    LayoutDistribution,
    LayoutTop,
    Row,
} from 'src/components/page-structure'
import { LoadingHeader } from './../../utils/loading/newsletters-list'
import {
    HeaderCategories,
    HeaderSearch,
    HeaderTag,
} from './../../components/newsletters-header'

type NewslettersProps = {
    list: {
        items: any[]
        total: number
        actualPageNumber: number
        loading: boolean
        vertical?: string | null
    }
    getNewslettersList: Function
    clearContentProps: Function
    changePageNumberProps: Function
    changeAvatarMenuProps: Function
    changePageMenu: Function
    loading: { content: boolean; header: boolean }
    user: any
    roles: any
    match: any
    menu: any
    status: any
    verticalFilter: string
    userBO: any
}

type IsCategory = {
    category: boolean
    id: number | null
    header: {
        name: string
        icon: string
        id: string
    }
}

type IsSearch = {
    query: string | null
}

const NewsletterList: FC<NewslettersProps> = ({
    list,
    getNewslettersList,
    clearContentProps,
    changePageNumberProps,
    changeAvatarMenuProps,
    changePageMenu,
    loading,
    user,
    roles,
    match,
    menu,
    status,
    verticalFilter,
    userBO,
}) => {
    const [isLoading, setIsLoading] = useState<{
        content: boolean
        header: boolean
    }>({ content: true, header: true })

    const [mixPanelFire, setMixPanelFire] = useState<boolean>(false)

    const contentHeight = useRef<HTMLElement>(null)

    const [isCategory, setIsCategory] = useState<IsCategory>({
        id: null,
        category: false,
        header: { name: '', icon: '', id: '' },
    })

    const [isSearch, setIsSearch] = useState<IsSearch>({
        query: null,
    })

    const history = useHistory()
    const TOTAL_PER_PAGE = 10
    const PAGINATION_LIMIT = 9
    let mixPanelControl = 0

    /**
     * useEffect Constructor
     */
    useEffect(() => {
        changePageNumberProps(1)

        return () => {
            changePageNumberProps(1)
        }
    }, [])

    /**
     * useEffect
     * Location Search
     */
    useEffect(() => {
        /**
         * Redirect Feature
         */
        const redirect = localStorage.getItem('JOTAPRO-redirect')

        if (redirect) {
            const redirectTemp = redirect
            localStorage.removeItem('JOTAPRO-redirect')
            history.push(redirectTemp)
        }

        /**
         * Clear content
         */
        clearContentProps()

        /**
         * Close Avatar Menu
         */
        changeAvatarMenuProps(false)

        /**
         * Is Category?
         * Description: Has category path and ID
         */
        if (match.path.includes('categoria')) {
            setIsCategory({
                ...isCategory,
                category: true,
                id: match.params.id,
            })
        } else if (match.params.newsletter === 'pesquisar') {
            /**
             * Is Search?
             * Description: Has 'pesquisa' word in path
             */
            setIsSearch({
                query: queryString.parse(location.search).q as string,
            })

            changePageNumberProps(1)
        }

        /**
         * Theme Color
         * Description: Apply the main color for this product on browser header
         */
        const tag = document.querySelector(
            'meta[name="theme-color"]'
        ) as HTMLHeadElement
        tag.setAttribute('content', `${colors.green.jotaGreen}`)

        /**
         * HOTJAR
         */
        if (user && user.attributes && user.attributes.email) {
            hotjarStart(
                {
                    email: user.attributes.email,
                    name: user.attributes.name,
                    roles: roles,
                },
                'tributos-list',
                '/tributos'
            )
        }

        /**
         * Unsubscribe
         */
        return () => {
            setIsLoading({
                header: true,
                content: true,
            })
            setMixPanelFire(false)
            setIsSearch({
                query: null,
            })
            setIsCategory({
                category: false,
                header: {
                    name: '',
                    icon: '',
                    id: '',
                },
                id: null,
            })
        }
    }, [location.search])

    /**
     * Monitor Menu
     */
    useEffect(() => {
        if (status && !status.error) {
            if (
                getPageType(match.path, match.params.newsletters) ===
                    'categories' &&
                list.items[0] &&
                list.items[0].category
            ) {
                const menuName = list.items[0].category.name
                    .replaceAll(' ', '-')
                    .toLowerCase()

                const verticalName = list.items[0].category.vertical.name
                    .replaceAll(' ', '-')
                    .toLowerCase()
                changePageMenu(`${verticalName}_${menuName}`)
            }
        }

        if (getPageType(match.path, match.params.newsletters) === 'tag') {
            changePageMenu('home-tag')
        }
    }, [list.items[0]])

    /**
     * NEWSLETTER LIST
     * Description: Load Content from API | Monitor Get Newsletter List
     */
    useEffect(() => {
        /**
         * Filter content by User Role
         */

        if (
            !filterUserByRole(roles, match.params.newsletter) &&
            match.params.newsletter != undefined &&
            match.params.newsletter !== 'categoria' &&
            match.params.newsletter !== 'pesquisar' &&
            match.params.newsletter !== 'tag' &&
            match.params.newsletter !== '' &&
            menu.activePage !== 'home' &&
            !menu.activePage.includes('tributos') &&
            !menu.activePage.includes('poder')
        ) {
            changePageMenu('404')
            history.push('/404')
        }

        if (Object.keys(roles).length !== 0) {
            getNewslettersList(
                list.actualPageNumber,
                getPageType(match.path, match.params.newsletter),
                match.params.id,
                verticalFilter,
                queryString.parse(location.search).q,
                roles,
                match.params.tag
            )
        }
    }, [
        list.actualPageNumber,
        match.params.id,
        location.search,
        match.params.newsletter,
        roles,
        verticalFilter,
        status,
    ])

    /**
     * MIX PANEL
     * Description: Fire events to Mix Panel
     */
    useEffect(() => {
        if (!mixPanelFire && list.total > 0) {
            setMixPanelFire(true)
            mixPanelControl = 0

            /**
             * MIX PANEL
             * User Data Shared
             */
            const data = {
                user: getUserDataMixPanel(user, userBO, 'user'),
                company: getUserDataMixPanel(user, userBO, 'company'),
            }

            if (mixPanelControl === 0 && list.total > 0) {
                mixPanelControl = 1
                switch (getPageType(match.path, match.params.newsletter)) {
                    case 'list':
                        mixPanelSetData({
                            id: user && user.username,
                            JOTA_title: `JOTA PRO - ${getPageType(
                                match.path,
                                match.params.newsletter
                            )}`,
                            JOTA_type: 'listagem',
                            user: data.user,
                            company: data.company,
                        })

                        break

                    case 'categories':
                        mixPanelSetData({
                            id: user && user.username,
                            JOTA_title: `JOTA PRO - ${getPageType(
                                match.path,
                                match.params.newsletter
                            )}`,
                            JOTA_type: 'category',
                            data: {
                                JOTA_vertical:
                                    list.items[0].category.vertical.name,
                                JOTA_category: list.items[0].category.name,
                                JOTA_category_total: list.total,
                            },
                            user: data.user,
                            company: data.company,
                        })

                        break

                    case 'search':
                        mixPanelSetData({
                            id: user && user.username,
                            JOTA_title: `JOTA PRO - ${getPageType(
                                match.path,
                                match.params.newsletter
                            )}`,
                            JOTA_type: 'busca',
                            data: {
                                JOTA_search_quantity: list.total,
                                JOTA_search_term: queryString.parse(
                                    location.search
                                ).q,
                            },
                            user: data.user,
                            company: data.company,
                        })
                        break

                    case 'tag':
                        mixPanelSetData({
                            id: user && user.username,
                            JOTA_title: `JOTA PRO - ${getPageType(
                                match.path,
                                match.params.newsletter
                            )} destiny`,
                            JOTA_type: 'tag destiny',
                            data: {
                                JOTA_vertical:
                                    list.items[0].category.vertical.name,
                                JOTA_category: list.items[0].category.name,
                                JOTA_category_total: list.total,
                                JOTA_tag: match.params.tag,
                            },
                            user: data.user,
                            company: data.company,
                        })

                        break
                }
            }
        }
        return () => {
            mixPanelControl = 0
            setMixPanelFire(false)
        }
    }, [list.total])

    /**
     * Loading from Redux
     */
    useEffect(() => {
        setIsLoading({
            ...isLoading,
            content: loading.content,
            header: loading.header,
        })

        return () => {
            setIsLoading({
                content: true,
                header: true,
            })
            setMixPanelFire(false)
        }
    }, [loading])

    /**
     * Category Content
     * Description: Update isCategory Header
     */
    useEffect(() => {
        if (list.items[0]) {
            setIsCategory({
                ...isCategory,
                id: match.params.id,
                category: true,
                header: {
                    name: list.items[0]?.category.name,
                    icon: list.items[0]?.category.icon,
                    id: list.items[0]?.category.id,
                },
            })
        }

        return () => {
            setIsCategory({
                ...isCategory,
                header: {
                    name: '',
                    icon: '',
                    id: '',
                },
            })

            mixPanelControl = 0
        }
    }, [list.items[0]])

    /**
     * Change Page Number Props
     */
    useEffect(() => {
        changePageNumberProps(1)
    }, [menu.activePage])

    /**
     * Content List
     * Description: List all content from api or show a dialog error
     * @return {any} React Element
     */
    const contentList = (): any => {
        /**
         * Has valid content?
         * Show Card Content in map array`
         */
        if (list && list.items && list.items.length > 0 && list.total > 0) {
            return list.items.map((item: any, key) => {
                return (
                    <CardListContainer
                        key={key}
                        data-tut={key === 0 && 'reactour__item'}
                        vertical={item.category.vertical.name}
                    >
                        <Card
                            newsLink={URLS.newsletter.conteudoSet(
                                item.category.vertical.name.toLowerCase(),
                                item.id,
                                getPageType(
                                    match.path,
                                    match.params.newsletter
                                ) === 'search'
                            )}
                            size='small'
                            title={item && item.title}
                            authorTagColor='gray'
                            authorTagLabel={dateConvert(item.send_time).date}
                            relatedTagColor='gray'
                            relatedTagLabel={dateConvert(item.send_time).time}
                            tagType='colored'
                            tagColor={colorByVertical(
                                item.category.vertical.name.toLowerCase()
                            )}
                            TagLeftIcon={
                                item && item.category && item.category.icon
                            }
                            tagLabel={
                                getPageType(
                                    match.path,
                                    match.params.newsletter
                                ) !== 'categories' &&
                                item &&
                                item.category &&
                                item.category.name
                            }
                            tagOnClick={() => {
                                changePageNumberProps(1)
                                clearContentProps()
                                history.push(
                                    `${URLS.newsletter.categoriaSet(
                                        item.category.vertical.name.toLowerCase(),
                                        item.category.id
                                    )}`
                                )
                            }}
                            relatedTagDisableHover
                            authorTagDisableHover
                            titleSearchTerm={
                                getPageType(
                                    match.path,
                                    match.params.newsletter
                                ) === 'search'
                                    ? isSearch.query ?? ''
                                    : ''
                            }
                        />
                    </CardListContainer>
                )
            })
        } else if (list.total !== 0 || !list)
            <Dialog message={BACKOFFICE_RESPONSES.NO_ACCESS} type='error' />
    }

    /**
     * Header Page
     * Component
     * @return {JSX} React Element
     */
    const getHeaderPage = () => {
        /**
         * Header Categories
         */
        if (
            isCategory.id &&
            isCategory.header &&
            !isLoading.header &&
            list &&
            list.items &&
            list.items.length > 0 &&
            list.items[0]
        ) {
            return (
                <HeaderCategories
                    categoryName={isCategory.header.name}
                    categoryIcon={isCategory.header.icon}
                    categoryId={isCategory.header.id}
                    vertical={
                        list.items[0].category &&
                        list.items[0].category.vertical &&
                        list.items[0].category.vertical.name
                    }
                />
            )

            /**
             * Header Search
             */
        } else if (isSearch.query && !isLoading.header) {
            return <HeaderSearch isSearch={isSearch} list={list} />

            /**
             * Header Tag
             */
        } else if (getPageType(match.path, match.params.newsletter) === 'tag') {
            return <HeaderTag tag={match.params.tag} />

            /**
             * Header List - normal
             */
        } else if (!isLoading.header) {
            return <HeaderPage>Início</HeaderPage>

            /**
             * Header Loading
             */
        } else {
            return <LoadingHeader />
        }
    }

    /**
     * Full Page
     * Return Header and Content
     * @return {React.Element}
     */
    const fullPage = () => (
        <LayoutTop>
            <HeaderPageList>
                {getHeaderPage()}
                {roles.has_tributos &&
                    roles.has_poder &&
                    !match.params.newsletter &&
                    !match.params.tag && <VerticalFilter />}
            </HeaderPageList>
            {isLoading.content ? (
                <NewslettersListLoading />
            ) : (
                <>
                    <Column>{contentList()}</Column>
                </>
            )}
        </LayoutTop>
    )

    return (
        <Container>
            <Modal
                text={MODAL.text}
                title={MODAL.title}
                moreLink={MODAL.link}
            />

            <Header term={isSearch.query as string} />

            <Row id='content'>
                <MenuComponent />
                <Content
                    id='newsletter_content'
                    ref={contentHeight}
                    menuIsOpen={menu.isOpen}
                >
                    <LayoutDistribution>
                        {status ? (
                            <DialogContainer>
                                <Dialog message={status} type='error'></Dialog>
                            </DialogContainer>
                        ) : (
                            fullPage()
                        )}

                        {!isLoading.content &&
                            list &&
                            list.total > PAGINATION_LIMIT && (
                                <PaginationContainer data-tut='reactour__pagination'>
                                    <Pagination
                                        key={list.items.length}
                                        color='black'
                                        currentPage={list.actualPageNumber}
                                        totalPages={Math.ceil(
                                            (list && list.total) /
                                                TOTAL_PER_PAGE
                                        )}
                                        handleChange={(_e, value) =>
                                            changePageNumberProps(value)
                                        }
                                    />
                                </PaginationContainer>
                            )}
                    </LayoutDistribution>
                </Content>
            </Row>
        </Container>
    )
}

/**
 * Map State to Props
 * @param {state} state
 * @return {redux}
 */
const mapStateToProps = (state: any) => ({
    list: state.newsletters.list,
    loading: state.newsletters.list.loading,
    user: state.auth.user,
    roles: state.auth.roles,
    menu: state.menu,
    status: state.newsletters.list.status,
    verticalFilter: state.newsletters.list.filter,
    userBO: state.auth.userBO,
})

/**
 * Interface Type Content
 * categoris, list, search
 */
export type contentInterface = 'categories' | 'list' | 'search' | 'tag'

/**
 * Map Dispatch to Props
 * @param {dispatch} dispatch
 * @param {dispatchMenu} dispatchMenu
 * @return {event}
 */
const mapDispatchToProps = (dispatch: any) => ({
    getNewslettersList: (
        page: number,
        categoryId: contentInterface,
        id: number,
        vertical?: string,
        query?: string,
        roles?: any,
        tag?: string
    ) => dispatch(getList(page, categoryId, id, vertical, query, roles, tag)),
    clearContentProps: () => dispatch(clearContentStore()),
    changePageNumberProps: (page: number) => dispatch(changePageNumber(page)),
    changePageMenu: (page: string) => dispatch(changePage(page)),
    changeAvatarMenuProps: (status: boolean) =>
        dispatch(changeAvatarMenuOpen(status)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterList)

export const PaginationContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 36px;
    max-width: 98%;
    margin: 0 auto;
    padding-top: 36px;
`

const DialogContainer = styled.div`
    margin-bottom: 24px;
`

const CardListContainer = styled.div<{ vertical: string }>`
    & img:nth-child(1) {
        filter: ${(props) => colorIconVertical(props.vertical)};
    }
`
