import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from './rootReducer'
import thunk from 'redux-thunk'

//  mount it on the Store
let store: any = null

// DEVTOOLS PLUGIN - Check
if ((window as any).__REDUX_DEVTOOLS_EXTENSION__) {
    store = createStore(
        rootReducer,
        compose(
            applyMiddleware(thunk),
            (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
                (window as any).__REDUX_DEVTOOLS_EXTENSION__()
        )
    )
} else {
    store = createStore(rootReducer, applyMiddleware(thunk))
}
const MyStore = store

export default MyStore
