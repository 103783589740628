import React, { useState, useEffect } from 'react'
import { Input, Button, Dialog } from '@JOTAJornalismo/jota-design-system'
import { Auth } from 'aws-amplify'
import LogoJota from './../../static/img/jotapro.png'
import {
    KnowJotaPro,
    ZendDeskComponent,
    PasswordChangeForm,
} from './../../components'
import './../../styles/pages/login.scss'
import { validateEmail } from '../../utils/helpers'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { AppDispatch, AppState } from './../../flux/types/auth'
import { changeAuthState, signIn } from './../../flux/ducks/auth'
import {
    BACKOFFICE_RESPONSES,
    ERROR,
    NORMAL,
    PASS_MINIMUM,
    URLS,
    COGNITO_RESPONSES,
    COGNITO_WARNINGS,
} from './../../utils/constants'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import queryString from 'query-string'
import { alternativeText } from './auth-utils'
import { colors, fonts } from 'src/static/theme'
import styled from 'styled-components'

type Props = {
    authState: string
    changeAuthState: (state: string) => void
    signInState: (email: string, password: string, newPass?: string) => void
    loading: boolean | null
    error: string
    user: any
    roles: any
    firstLogin: boolean
}

type LoginStateI = {
    values: {
        email: string
        password: string
    }
    messages: {
        email: string
        password: string
    }
    status: {
        email: 'error' | 'normal' | 'disabled' | 'success'
        password: 'error' | 'normal' | 'disabled' | 'success'
    }
    errorMessage: string
    isLoading: boolean
    passBlur: boolean
    buttonStatus: boolean
}

export type FirstLoginStateI = {
    passwordStatus: boolean
    password: string
    reset: boolean
}

const Login: React.FC<Props> = ({
    signInState,
    loading,
    error,
    user,
    roles,
    firstLogin,
}) => {
    const history = useHistory()
    const [loginState, setLoginState] = useState<LoginStateI>({
        values: {
            email: '',
            password: '',
        },
        messages: {
            email: '',
            password: '',
        },
        status: {
            email: NORMAL,
            password: NORMAL,
        },
        errorMessage: '',
        isLoading: false,
        passBlur: false,
        buttonStatus: false,
    })

    const [firstLoginState, setFirstLoginState] = useState<FirstLoginStateI>({
        passwordStatus: false,
        password: '',
        reset: false,
    })

    const [firstLoading, setFirstLoading] = useState<boolean>(false)

    useEffect(() => {
        Auth.currentAuthenticatedUser().then((userTemp: any) => {
            if (userTemp) {
                history.push(URLS.root)
            }
        })

        const params = queryString.parse(location.search) as any

        if (params.erro === 'tributos') {
            setLoginState({
                ...loginState,
                errorMessage: COGNITO_RESPONSES.ROLE_ERROR,
            })
        }

        const tag = document.querySelector(
            'meta[name="theme-color"]'
        ) as HTMLHeadElement
        tag.setAttribute('content', `${colors.gray.jotaGrayLighter}`)
    }, [])

    /**
     * useEffect
     */
    useEffect(() => {
        /**
         * User is authenticad - redirect to root page
         */
        if (user && error === '' && roles) {
            history.push(URLS.root)
        } else if (error) {
            switch (error) {
                case COGNITO_WARNINGS.PASS_EXCEEDED:
                    setLoginState({
                        ...loginState,
                        errorMessage: COGNITO_RESPONSES.PASS_EXCEEDED,
                        isLoading: false,
                    })
                    break

                case COGNITO_WARNINGS.INCORRECT:
                    setLoginState((state) => ({
                        ...state,
                        errorMessage: COGNITO_RESPONSES.INCORRECT,
                        isLoading: false,
                    }))
                    break

                case COGNITO_WARNINGS.PRE_TOKEN:
                    setLoginState({
                        ...loginState,
                        errorMessage: COGNITO_RESPONSES.PRE_TOKEN,
                        isLoading: false,
                    })
                    break
            }
        }
    }, [user, error, loading])

    /**
     * Login Submit
     * @param {any} e
     */
    const loginSubmit = (e: any) => {
        e.preventDefault()

        const { email, password } = loginState.values

        if (!email && !password) return

        setLoginState({
            ...loginState,
            isLoading: true,
            errorMessage: '',
        })

        signInState(email, password)
    }

    /**
     * Error Dialog
     * Cognito Exclusive
     * @return {React.Element}
     */
    const errorShowCognito = () =>
        error &&
        error !== '' &&
        error === 'no-user-access' && (
            <div style={{ marginBottom: '24px' }}>
                <Dialog
                    message={<div>{BACKOFFICE_RESPONSES.NO_ACCESS}</div>}
                    type='error'
                />
            </div>
        )

    /**
     * Error Dialog
     * Another errors
     * @return {React.Element}
     */
    const errorShow = () =>
        errorMessage && (
            <div style={{ marginBottom: '24px' }}>
                <Dialog message={errorMessage} type='error' />
            </div>
        )

    /**
     * If the email is valid, set the email state to the value
     * @param {any} value - The value of the input field.
     * @return {void}
     */
    const emailValidationChange = (value) => {
        if (validateEmail(value) && value !== '') {
            setLoginState({
                ...loginState,
                values: {
                    ...loginState.values,
                    email: value,
                },
                messages: {
                    ...loginState.messages,
                    email: '',
                },
                status: {
                    ...loginState.status,
                    email: NORMAL,
                },
                buttonStatus:
                    loginState.values.password && value ? true : false,
            })
        } else {
            setLoginState({
                ...loginState,
                buttonStatus: false,
            })
        }
    }

    /**
     * If the email is empty or invalid, set the email state
     * to an empty string and set the email status to error
     * @param {any} e - The event object that was triggered.
     * @return {void}
     */
    const emailValidationBlur = (e) => {
        const { value } = e.target

        if (value === '' || !validateEmail(value)) {
            setLoginState({
                ...loginState,
                values: {
                    ...loginState.values,
                    email: '',
                },
                messages: {
                    ...loginState.messages,
                    email: 'Digite um e-mail válido',
                },
                status: {
                    ...loginState.status,
                    email: 'error',
                },
                buttonStatus: false,
            })
        }
    }

    /**
     * Login Form
     * Description: Login screen
     * Email Input
     * Password Input
     * @return {React.Element}
     */
    const loginForm = () => (
        <div>
            <h1>Olá!</h1>
            <p>
                Faça <strong>login</strong> para acessar sua área logada.
            </p>

            {errorShowCognito()}
            {errorShow()}

            {isLoading ? (
                <LoginLoading />
            ) : (
                <div>
                    <Input
                        startFocus
                        type='email'
                        placeholder=''
                        onBlur={(e) => emailValidationBlur(e)}
                        onChange={(value: any) => emailValidationChange(value)}
                        label='e-mail'
                        id='email'
                        alternativeText={alternativeText(messages.email)}
                        showPrefixIcon={false}
                        status={status.email}
                    />

                    <div style={{ marginTop: '24px' }}>
                        <Input
                            type='password'
                            onBlur={(e) => {
                                const { value } = e.target

                                if (
                                    value !== '' &&
                                    value.length < PASS_MINIMUM
                                ) {
                                    setLoginState({
                                        ...loginState,
                                        values: {
                                            ...loginState.values,
                                            password: '',
                                        },
                                        messages: {
                                            ...loginState.messages,
                                            password:
                                                COGNITO_RESPONSES.PASS_MINIMUM,
                                        },
                                        status: {
                                            ...loginState.status,
                                            password: ERROR,
                                        },
                                        passBlur: true,
                                        buttonStatus: false,
                                    })
                                }
                            }}
                            onChange={(value: any) => {
                                if (
                                    value !== '' &&
                                    value.length >= PASS_MINIMUM
                                ) {
                                    setLoginState({
                                        ...loginState,
                                        values: {
                                            ...loginState.values,
                                            password: value,
                                        },
                                        messages: {
                                            ...loginState.messages,
                                            password: '',
                                        },
                                        status: {
                                            ...loginState.status,
                                            password: NORMAL,
                                        },
                                        buttonStatus:
                                            loginState.values.email && value
                                                ? true
                                                : false,
                                    })
                                } else if (loginState.passBlur) {
                                    setLoginState({
                                        ...loginState,
                                        values: {
                                            ...loginState.values,
                                            password: '',
                                        },
                                        messages: {
                                            ...loginState.messages,
                                            password:
                                                COGNITO_RESPONSES.PASS_MINIMUM,
                                        },
                                        status: {
                                            ...loginState.status,
                                            password: ERROR,
                                        },
                                        buttonStatus: false,
                                    })
                                }
                            }}
                            label='senha'
                            alternativeText={alternativeText(messages.password)}
                            showPrefixIcon={false}
                            status={status.password}
                        />
                    </div>
                </div>
            )}
            <div className='login-options-container'>
                <a href={URLS.esqueci} id='forgot_password'>
                    Esqueceu a senha?
                </a>
            </div>
            <Button
                label='entrar'
                size='medium'
                type='filled'
                disabled={!loginState.buttonStatus}
                width='100%'
                isSubmitType
                onClick={(e) => loginSubmit(e)}
            />
        </div>
    )

    /**
     * First Login - Screen
     * @return {React.Element}
     */
    const firstLoginScreen = () => (
        <div>
            <h1>Boas vindas!</h1>
            <DescriptionText>
                Identificamos que este é seu primeiro login na plataforma{' '}
                <strong>JOTA PRO.</strong> Para ter acesso aos nossos conteúdos,
                você deve criar uma nova senha clicando no botão abaixo.
            </DescriptionText>
            {!firstLoading ? (
                <div>
                    <PasswordChangeForm
                        state={firstLoginState}
                        setState={setFirstLoginState}
                        reset={firstLoginState.reset}
                    />
                </div>
            ) : (
                <LoginLoadingFirst />
            )}

            <Button
                label='Redefinir a senha'
                size='medium'
                type='filled'
                disabled={!firstLoginState.passwordStatus}
                width='100%'
                onClick={() => {
                    signInState(
                        loginState.values.email,
                        loginState.values.password,
                        firstLoginState.password
                    )
                    setFirstLoading(true)
                }}
            />
        </div>
    )

    const { errorMessage, status, messages, isLoading } = loginState

    return (
        <div className='login-page'>
            <ZendDeskComponent />
            <form className='login-container'>
                <div className='login-image-container'>
                    <img src={LogoJota} alt='JOTA PRO logo' />
                </div>
                {!firstLogin ? loginForm() : firstLoginScreen()}
            </form>

            <KnowJotaPro />
        </div>
    )
}

export const mapStateToProps = (state: AppState) => ({
    user: state.auth.user,
    authState: state.auth.authState,
    loading: state.auth.loading,
    error: state.auth.error,
    roles: state.auth.roles,
    firstLogin: state.auth.firstLogin,
})

export const mapDispatchToProps = (dispatch: AppDispatch) => ({
    changeAuthState: (value: string) => dispatch(changeAuthState(value)),
    signInState: (email: string, password: string, newPassword?: string) => {
        dispatch(signIn(email, password, newPassword))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)

/**
 * Skeleton Loading Login
 * @return {object}
 */
const LoginLoading = () => (
    <SkeletonTheme baseColor='#ffffff50' highlightColor='#44444420'>
        <p style={{ margin: 0, marginBottom: '15px' }}>
            <Skeleton count={1} width='80px' />
            <Skeleton count={1} width='100%' height='40px' />
        </p>
        <p style={{ margin: 0 }}>
            <Skeleton count={1} width='80px' />
            <Skeleton count={1} width='100%' height='40px' />
        </p>
    </SkeletonTheme>
)

/**
 * Skeleton Loading - First Login
 * @return {object}
 */
const LoginLoadingFirst = () => (
    <SkeletonTheme baseColor='#ffffff50' highlightColor='#44444420'>
        <p style={{ margin: 0, marginBottom: '15px', marginTop: '24px' }}>
            <Skeleton count={1} width='80px' />
            <Skeleton count={1} width='100%' height='40px' />
        </p>
        <p style={{ margin: 0 }}>
            <Skeleton count={1} width='80px' />
            <Skeleton count={1} width='100%' height='40px' />
        </p>
        <p style={{ margin: 0, marginBottom: '24px' }}>
            <Skeleton count={2} width='100%' height='40px' />
        </p>
    </SkeletonTheme>
)

const DescriptionText = styled.div`
    color: ${colors.gray.jotaGrayDark};
    ${fonts.jotaBody}
`
