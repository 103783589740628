import { Input } from '@JOTAJornalismo/jota-design-system'
import React, { FC, useState, useEffect } from 'react'
import { COGNITO_RESPONSES } from './../utils/constants'
import PasswordRequirements from './password-requirements'

type PasswordRulesI = {
    hasUpper: boolean | null
    hasLower: boolean | null
    hasNumber: boolean | null
    has8Chars: boolean | null
    hasPasswordRewrite: boolean | null
    blur: {
        new: boolean | null
        retype: boolean | null
    }
}

type ChangeFlowI = {
    password: {
        value: string
        status: 'normal' | 'success' | 'error'
        message: string
    }
    retype: {
        value: string
        status: 'normal' | 'success' | 'error'
        message: string
    }
}

type PasswordChangeFormI = {
    state: any
    setState: (state: any) => void
    reset?: boolean
}

const PasswordChangeForm: FC<PasswordChangeFormI> = ({
    state,
    setState,
    reset = false,
}) => {
    const [passRules, setPassRules] = useState<PasswordRulesI>({
        hasUpper: null,
        hasLower: null,
        hasNumber: null,
        has8Chars: null,
        hasPasswordRewrite: null,
        blur: {
            new: null,
            retype: null,
        },
    })

    const [changeFlow, setChangeFlow] = useState<ChangeFlowI>({
        password: {
            value: '',
            status: 'normal',
            message: '',
        },
        retype: {
            value: '',
            status: 'normal',
            message: '',
        },
    })

    const [overField, setOverField] = useState<{
        new: boolean | null
        retype: boolean | null
    }>({ new: null, retype: null })

    /**
     * Reset Event - Clear data
     */
    useEffect(() => {
        if (reset) {
            onClickResetPassword()
        }
    }, [reset])

    /**
     * Send data to parent
     */
    useEffect(() => {
        if (
            changeFlow.password.value !== '' &&
            changeFlow.password.value === changeFlow.retype.value &&
            changeFlow.retype.value !== ''
        ) {
            setState({
                ...state,
                passwordStatus: true,
                password: changeFlow.password.value,
            })
        } else {
            setState({
                ...state,
                passwordStatus: false,
                password: '',
            })
        }

        return () => {
            setState({
                ...state,
                passwordStatus: false,
                password: '',
            })
        }
    }, [changeFlow])

    /**
     * Check Password Rules
     * Has at least 8 characters
     * Has an upper char
     * Has a lower char
     * Has a number
     * @param {any} e
     * @return {void}
     */
    const checkPasswordRules = (e: any): void => {
        if (!e) return

        setPassRules({
            ...passRules,
            has8Chars: e.length > 7 ? true : false,
            hasUpper: /[A-Z]/.test(e) ? true : false,
            hasNumber: /\d/.test(e) ? true : false,
            hasLower: /[a-z]/.test(e) ? true : false,
        })

        // all conditions is true
        if (
            e.length > 7 &&
            /[A-Z]/.test(e) &&
            /\d/.test(e) &&
            /[a-z]/.test(e)
        ) {
            setChangeFlow({
                ...changeFlow,
                password: {
                    ...changeFlow.password,
                    value: e,
                    status: 'success',
                },
            })
        } else {
            setChangeFlow({
                ...changeFlow,
                password: {
                    ...changeFlow.password,
                    value: '',
                    status: 'normal',
                },
            })
        }
    }

    /**
     * Check Retype Password
     * @param {any} e
     * @return {void}
     */
    const checkRetypePassword = (e) => {
        const { value } = e.target

        if (!value) return

        setChangeFlow({
            ...changeFlow,
            retype: {
                ...changeFlow.retype,
                status:
                    value === changeFlow.password.value ? 'success' : 'normal',
                value: value,
                message: '',
            },
        })

        /**
         * Check if the both password are the same
         */
        setPassRules({
            ...passRules,
            hasPasswordRewrite:
                value === changeFlow.password.value ? true : false,
        })
    }

    /**
     * Reset Data Form
     */
    const onClickResetPassword = (): void => {
        setChangeFlow({
            password: {
                value: '',
                status: 'normal',
                message: '',
            },
            retype: {
                value: '',
                status: 'normal',
                message: '',
            },
        })

        setPassRules({
            hasUpper: null,
            hasLower: null,
            hasNumber: null,
            has8Chars: null,
            hasPasswordRewrite: null,
            blur: {
                new: null,
                retype: null,
            },
        })

        setState({
            ...state,
            reset: false,
        })
    }

    /**
     * Check Status
     * New Email Field
     * @return {string}
     */
    const checkStatus = () => {
        if (
            passRules.has8Chars &&
            passRules.hasLower &&
            passRules.hasNumber &&
            passRules.hasUpper &&
            overField.new === false
        ) {
            return 'success'
        } else if (
            changeFlow.password.value !== '' &&
            passRules.blur['new'] &&
            overField.new === false
        ) {
            return 'error'
        } else {
            return 'normal'
        }
    }

    const checkStatusRetype = () => {
        if (passRules.hasPasswordRewrite && passRules.blur['retype']) {
            return 'success'
        } else if (passRules.blur['retype']) {
            return 'error'
        } else {
            return 'normal'
        }
    }

    return (
        <React.Fragment>
            <div style={{ margin: '24px 0' }}>
                <Input
                    clearContent={{ data: reset, fn: setState }}
                    type='password'
                    required
                    label='Nova senha'
                    showPrefixIcon={false}
                    status={checkStatus()}
                    onChange={(e: any) => checkPasswordRules(e)}
                    onFocus={() => {
                        setOverField({
                            ...overField,
                            new: true,
                        })
                    }}
                    onBlur={() => {
                        setOverField({
                            ...overField,
                            new: false,
                        })

                        setPassRules({
                            ...passRules,
                            blur: {
                                ...passRules.blur,
                                new: true,
                            },
                        })
                    }}
                />
            </div>

            <Input
                clearContent={{ data: reset, fn: setState }}
                type='password'
                required
                label='Confirme a Nova senha'
                showPrefixIcon={false}
                alternativeText={
                    passRules.hasPasswordRewrite === false
                        ? COGNITO_RESPONSES.INPUT_PASS_NOT_EQUAL
                        : ''
                }
                status={checkStatusRetype()}
                onBlur={(e: any) => {
                    checkRetypePassword(e)
                    setOverField({
                        ...overField,
                        retype: false,
                    })
                    setPassRules({
                        ...passRules,
                        hasPasswordRewrite:
                            changeFlow.password.value === e.target.value
                                ? true
                                : false,
                        blur: {
                            ...passRules.blur,
                            retype: true,
                        },
                    })
                }}
                onFocus={() => {
                    setOverField({
                        ...overField,
                        retype: true,
                    })
                }}
            />

            <PasswordRequirements
                passwordRules={passRules}
                setPasswordRules={setPassRules}
            />
        </React.Fragment>
    )
}

export default PasswordChangeForm
