import { colors } from './../static/theme'

/**
 * Validate Email
 * Description: Check if email is valid or not
 * @param {string} email
 * @return {boolean}
 */
export const validateEmail = (email: string): boolean => {
    const re = /^[^@]+@[^@]+\.[^@\.]{2,}$/
    return re.test(String(email).toLowerCase())
}

/**
 * JWT Token - Decode
 * @param {string} token
 * @return {object} object
 */
export const decodeJWT = (token: string): any => {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join('')
    )

    return JSON.parse(jsonPayload)
}

/**
 * dateConvert
 * Description: Convert timestamp to date
 * @param {any} value
 * @return {date} date
 */
export const dateConvert = (value: string): { date: string; time: string } => {
    return {
        date: new Date(value).toLocaleDateString('pt-BR', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }),
        time: new Date(value).toLocaleTimeString('pt-BR', {
            hour: '2-digit',
            minute: '2-digit',
        }),
    }
}

/**
 * MailChimp Table Sanitize
 * Description: Clean Mailchimp HTML table
 * @param {string} html
 * @return {string} new html
 */
export const cleanMCTable = (html: string) => {
    if (html) {
        /**
         * Clean by replace all tags
         */
        return html
            .replaceAll('!important', '')
            .replaceAll(
                `templateContainer{\n\t\t\tmax-width:600px !important;\n\t\t}`,
                ''
            )
            .replaceAll(`padding-top: 9px;`, ``)
            .replaceAll("b'", '')
            .replaceAll('\\n', '')
            .replaceAll('\\t', '')
            .replaceAll('&nbsp;', ' ')
            .replaceAll('max-width: 600px !important;', '')
            .replaceAll('width:600px !important;', '')
            .replaceAll('!important', '')
            .replaceAll('600px', 'inherit')
            .replaceAll(
                "font-family:'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;",
                'font-family:Roboto, sans-serif !important;'
            )
            .replaceAll(
                'font-family:Helvetica;',
                'font-family:Roboto, sans-serif !important;'
            )
            .replaceAll('align="center"', 'align="left"')
            .replaceAll('#2BAADF', colors.green.jotaGreen + '!important')
            .replaceAll('color: #000000', 'color:  #3C3F4C !important')
            .replaceAll('text-align:center;', 'text-align:inherit;')
            .replaceAll('text-align: center;', 'text-align:inherit;')
            .replaceAll('#FF0000', '#3C3F4C')
            .replaceAll('background-color: #404040', 'background-color: none')
            .replaceAll('color: #F2F2F2', 'color: #000000')
            .replaceAll('color="#ff0000"', 'color="#3C3F4C"')
    }
}

/**
 * It returns a color based on the type of card.
 * @param {string} type - The type of the card.
 * @return {string} A function that returns a color based on the type of card.
 */
export const colorByVertical = (
    type: string
):
    | 'blue'
    | 'green'
    | 'white'
    | 'black'
    | 'gray'
    | 'red'
    | 'orange'
    | 'yellow'
    | 'cyan'
    | 'purple' => {
    switch (type) {
        case 'poder':
            return 'blue'
        case 'tributos':
            return 'green'
        default:
            return 'black'
    }
}

/**
 * Has Permission?
 * Description: Check if this role has on Roles Object Redux
 * @param {any} roles
 * @param {string} roleCheck
 * @return {boolean}
 */
export const hasPermission = (roles, roleCheck) => {
    const indexName = `has_${roleCheck.toLowerCase()}`
    return roles[indexName] ? true : false
}

/**
 * Return fill for svg icons
 * @param {string} vertical
 * @return {string} fill css code
 */
export const colorIconVertical = (vertical: string) => {
    const verticalTemp = vertical.toLowerCase()
    switch (verticalTemp) {
        case 'poder':
            return 'invert(42%) sepia(29%) saturate(3269%) hue-rotate(209deg) brightness(110%) contrast(101%)'
        case 'tributos':
            return 'invert(52%) sepia(17%) saturate(1204%) hue-rotate(111deg) brightness(98%) contrast(85%);'
        case 'menu':
            return 'invert(0%) sepia(0%) saturate(0%) hue-rotate(192deg) brightness(80%) contrast(92%);'
        case 'orange':
            return 'invert(49%) sepia(57%) saturate(3778%) hue-rotate(337deg) brightness(99%) contrast(90%);'
    }
}

/**
 * Get Type of Page
 * List, Categories, Search or Tag
 * @param {string} match
 * @param {string} newsletter
 * @return {string} - categoria, pesquisar, list or tag type
 */
export const getPageType = (match: string, newsletter: string): string => {
    /**
     * has 'categoria' on path
     */
    if (match.includes('categoria')) {
        return 'categories'

        /**
         * has 'pesquisar' on path
         * or newsletter like 'pesquisar'
         */
    } else if (match.includes('pesquisar') || newsletter === 'pesquisar') {
        return 'search'

        /**
         * has 'tag' string on path
         */
    } else if (match.includes('tag')) {
        return 'tag'

        /**
         * else is list normal
         */
    } else {
        return 'list'
    }
}

/**
 * Return HTML string to HTML Element
 * @param {string} html
 * @return {Document} html
 */
export const getHTMLParser = (html: string): Document => {
    const parser = new DOMParser()
    return parser.parseFromString(html, 'text/html')
}

/**
 * Get UserData MixPanel
 * @param {any} user
 * @param {any} userBO
 * @param {string} type
 * @return {object}
 */
export const getUserDataMixPanel = (user: any, userBO: any, type: string) => {
    if (type === 'user') {
        return {
            JOTA_userEmail: user && user.attributes && user.attributes.email,
            JOTA_userFirstName:
                user && user.attributes && user.attributes.given_name,
            JOTA_userLastName:
                user && user.attributes && user.attributes.family_name,
        }
    } else
        return {
            JOTA_companyField:
                userBO && userBO.profile && userBO.profile.company_field,
            JOTA_companyName:
                userBO && userBO.profile && userBO.profile.company_name,
            JOTA_companyTier:
                userBO && userBO.profile && userBO.profile.company_tier,
            JOTA_companyType:
                userBO && userBO.profile && userBO.profile.company_type,
            JOTA_companyWorkersCount:
                userBO &&
                userBO.profile &&
                userBO.profile.company_workers_count,
        }
}
