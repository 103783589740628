import React, { useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { connect } from 'react-redux'
import { AppDispatch, AppState } from './../../flux/types/auth'
import { signOut } from './../../flux/ducks/auth'
import 'react-loading-skeleton/dist/skeleton.css'

type Props = {
    signOutStore: () => void
}

const LogoutPage: React.FC<Props> = ({ signOutStore }) => {
    useEffect(() => {
        Auth.currentAuthenticatedUser().then((user) => {
            if (user) {
                signOutStore()
            }
        })
    }, [])

    return <div className='login-page'>Aguarde</div>
}

export const mapStateToProps = (state: AppState) => ({
    user: state.auth.user,
    authState: state.auth.authState,
    loading: state.auth.loading,
    error: state.auth.error,
    roles: state.auth.roles,
})

export const mapDispatchToProps = (dispatch: AppDispatch) => ({
    signOutStore: () => dispatch(signOut()),
})

export default connect(mapStateToProps, mapDispatchToProps)(LogoutPage)
