import { colors, MOBILE_SIZE } from 'src/static/theme'
import styled from 'styled-components'

/*
 * Page Structure
 * Share div struture betweens many components and pages
 */

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
`

export const Content = styled.div<{ menuIsOpen: boolean }>`
    display: flex;
    flex-direction: column;
    width: ${(props) => (props.menuIsOpen ? '80%' : 'calc(100% - 56px);')};
    padding: 44px;
    margin-left: ${(props) => (props.menuIsOpen ? '20%' : '3%')};
    box-sizing: content-box;
    margin-top: 56px;
    height: fit-content;

    @media (max-width: ${MOBILE_SIZE}) {
        padding: 18px;
        width: 90%;
        margin-left: 0;
        margin-top: 54px;
    }
`
export const LayoutDistribution = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
    box-sizing: content-box;
`

export const LayoutTop = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    overflow: auto;

    @media (max-width: ${MOBILE_SIZE}) {
        flex-direction: column;
    }
`

export const HeaderPageTXT = styled.h1`
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    margin: 0;
    color: ${colors.black.jotaBlack};
`

export const HeaderPage = styled(HeaderPageTXT)``

export const Menu = styled.div`
    width: 20%;
    position: fixed;
    margin-top: 56px;
    @media (max-width: 800px) {
        display: none;
    }
`

export const ColumnContainer = styled.div`
    display: flex;
    flex-direction: rows;
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
`

export const Column = styled.div`
    display: grid;
    grid-template-areas: 'column1 column2';
    grid-gap: 36px;

    @media (max-width: ${MOBILE_SIZE}) {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
`

export const HeaderPageList = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-content: center;
    flex-direction: row;
    align-items: center;
    padding-bottom: 12px;
    border-bottom: 1px solid #e6e8ed;
    margin-top: 0;
    margin-bottom: 36px;
    @media (max-width: ${MOBILE_SIZE}) {
        margin-top: 18px;
        flex-direction: column;
        align-items: flex-start;
    }
`
