import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { PageNotFound, ProfilePage } from '../pages'
import { URLS } from './constants'
import PrivateRoute from './PrivateRoute'

// pages
import {
    LoginPage,
    ForgotPassword,
    ResetPassword,
    SSO,
    LogoutPage,
} from './../pages/auth'
import { NewsletterList, NewsletterContent } from '../pages/newsletter'

const Routes = () => {
    return (
        <BrowserRouter>
            <Switch>
                {/* 404 Route - Last Route (not move) */}
                <PrivateRoute exact path='/404' component={PageNotFound} />

                {/* Public Routes */}
                <Route exact path={URLS.login} component={LoginPage} />
                <Route path={`${URLS.login}/:id`} component={LoginPage} />
                <Route exact path={URLS.esqueci} component={ForgotPassword} />
                <Route exact path={URLS.reset} component={ResetPassword} />
                <Route exact path={URLS.loginSSO} component={SSO} />
                <PrivateRoute exact path={URLS.logout} component={LogoutPage} />

                {/* Private Routes - Authentication */}

                {/* Profiles */}
                <PrivateRoute
                    exact
                    path={URLS.profile}
                    component={ProfilePage}
                />

                {/* Newsletters • Tributos Routes */}
                <PrivateRoute
                    exact
                    path={URLS.newsletter.list}
                    component={NewsletterList}
                />

                {/* Newsletter • Categories */}
                <PrivateRoute
                    exact
                    path={URLS.newsletter.categoria}
                    component={NewsletterList}
                />

                {/* Newsletter • Search */}
                <PrivateRoute
                    exact
                    path={URLS.newsletter.search}
                    component={NewsletterList}
                />

                {/* Newsletter • Tag */}
                <PrivateRoute
                    exact
                    path={URLS.newsletter.tag}
                    component={NewsletterList}
                />

                {/* Newsletter • Content */}
                <PrivateRoute
                    path={URLS.newsletter.conteudo}
                    component={NewsletterContent}
                />

                {/* Default Route */}
                <PrivateRoute exact path='/' component={NewsletterList} />
            </Switch>
        </BrowserRouter>
    )
}

export default Routes
