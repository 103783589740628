import React from 'react'
import styled from 'styled-components'
import { Button } from '@JOTAJornalismo/jota-design-system'
import { colors, fonts } from '../static/theme'
import { JOTA } from './../utils/constants'

const KnowJotaPro = () => {
    return (
        <Container>
            <TextContainer>
                <Title>
                    Jornalismo e tecnologia para tomadores de decisão.
                </Title>
                <Text>
                    O <JotaPRO href={JOTA.PRO}>JOTA PRO</JotaPRO> é uma
                    plataforma que centraliza soluções de monitoramento e
                    análise política e jurídica para empresas. Nós ajudamos
                    profissionais de departamentos jurídicos e de relações
                    governamentais a compreenderem melhor o cenário
                    institucional brasileiro para tomarem decisões de forma
                    simples e segura.
                </Text>
                <Subtitle>AINDA NÃO POSSUI UMA CONTA PRO?</Subtitle>
                <Text style={{ margin: '0px 0 36px 0' }}>
                    Experimente nossos conteúdos de graça e veja como o JOTA PRO
                    pode te ajudar na tomada de decisão.
                </Text>
            </TextContainer>
            <a href={JOTA.PRO} style={{ textDecoration: 'none' }}>
                <Button label='conheça agora' size='large' type='filled' />
            </a>

            <TextContainer>
                <Text>
                    Quer ler as matérias do JOTA? Acesse{' '}
                    <JotaPRO href={JOTA.INFO}>jota.info</JotaPRO>
                </Text>
            </TextContainer>
        </Container>
    )
}

export default KnowJotaPro

const Container = styled.section`
    padding: 0 68px;
    width: 60vw;
    height: 100vh;
    position: relative;
    background: linear-gradient(
            180deg,
            rgba(31, 33, 40, 0.8) 0%,
            ${colors.black.jotaBlackLight} 100%
        ),
        url('./img/bg-brasilia-4.jpg');
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 550px) {
        padding: 0 18px;
        width: 100vw;
        box-sizing: border-box;
    }
`

const TextContainer = styled.div`
    max-width: 554px;
`

const Title = styled.h1`
    color: #ffffff;
    font-weight: bold;
    font-size: 54px;
    line-height: 62px;
    margin: 0 0 24px 0;

    @media (max-width: 550px) {
        ${fonts.jotaHeading03()}
    } ;
`

const JotaPRO = styled.a`
    color: #ffffff;
    font-weight: bold;
    text-decoration: underline;

    &:hover {
        cursor: pointer;
        text-decoration: none;
    }
`

const Subtitle = styled.h3`
    color: #ffffff;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin: 54px 0 8px 0;
`

const Text = styled.p`
    color: #d3d3d9;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    @media (max-width: 550px) {
        margin: 0 0 48px 0;
    } ;
`
