export const colors = {
    white: {
        jotaWhite: '#ffffff',
    },
    black: {
        jotaBlack: '#000000',
        jotaBlackLight: '#1F2128',
    },
    gray: {
        jotaGrayDarker: '#3C3F4C',
        jotaGrayDark: '#73757F',
        jotaGray: '#9899A2',
        jotaGrayLight: '#D3D3D9',
        jotaGrayLighter: '#E6E8ED',
    },
    red: {
        jotaRedDarker: '#56040D',
        jotaRedDark: '#8B0208',
        jotaRed: '#CE2828',
        jotaRedLight: '#FB8B8C',
        jotaRedLighter: '#FCB3B3',
    },
    orange: {
        jotaOrangeDarker: '#7C1600',
        jotaOrangeDark: '#AF2E1D',
        jotaOrange: '#F05841',
        jotaOrangeLight: '#FFA996',
        jotaOrangeLighter: '#FFC9BD',
    },
    yellow: {
        jotaYellowDarker: '#B88114',
        jotaYellowDark: '#EAAD2A',
        jotaYellow: '#FED350',
        jotaYellowLight: '#FFEE93',
        jotaYellowLighter: '#FFF9C5',
    },
    blue: {
        jotaBlueDarker: '#1A419E',
        jotaBlueDark: '#3766D2',
        jotaBlue: '#5588FF',
        jotaBlueLight: '#85A9FF',
        jotaBlueLighter: '#C2D4FF',
    },
    cyan: {
        jotaCyanDarker: '#12699C',
        jotaCyanDark: '#0089BF',
        jotaCyan: '#30B1E4',
        jotaCyanLight: '#90D9F5',
        jotaCyanLighter: '#CFF2FF',
    },
    purple: {
        jotaPurpleDarker: '#410E28',
        jotaPurpleDark: '#662446',
        jotaPurple: '#915172',
        jotaPurpleLight: '#DA95B8',
        jotaPurpleLighter: '#F1BFD9',
    },
    green: {
        jotaGreenDarker: '#10523E',
        jotaGreenDark: '#23735B',
        jotaGreen: '#3F9B7F',
        jotaGreenLight: '#95CFBD',
        jotaGreenLighter: '#BEEBDD',
    },
    transparent: {
        jotaTBlack24: 'rgba(0, 0, 0, 0.24)',
        jotaTGray40: 'rgba(152, 153, 162, 0.4)',
        jotaTGray50: 'rgba(152, 153, 162, 0.5)',
        jotaTGrayDark20: 'rgba(115, 117, 127, 0.2)',
        jotaTGrayLight40: 'rgba(211, 211, 217, 0.4)',
        jotaTgrayLighter40: 'rgba(230, 232, 237, 0.4)',
        jotaTWhite50: 'rgba(255, 255, 255, 0.5)',
        jotaTRedLighter40: 'rgba(252, 179, 179, 0.4)',
        jotaTGreenLighter40: 'rgba(190, 235, 221, 0.4)',
        jotaTYellowLighter40: 'rgba(255, 249, 197, 0.4)',
        jotaTCyanLighter40: ' rgba(207, 242, 255, 0.4)',
    },
}

export const fonts = {
    jotaBodyLarge: () => `
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.75rem;
    `,
    jotaBody: () => `
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    `,
    jotaBodySmall: () => `
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    `,
    jotaBodyXSmall: () => `
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1rem;
    `,
    jotaQuote: () => `
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 1.5rem;
    line-height: 2rem;
    `,
    jotaOverlineLarge: () => `
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    `,
    jotaOverline: () => `
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-transform: uppercase;
    `,
    jotaOverlineSmall: () => `
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.01rem;
    `,
    jotaOverlineXSmall: () => `
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 0.688rem;
    line-height: 0.875rem;
    text-transform: uppercase;
    letter-spacing: 0.01rem;
    `,
    jotaOverlineCondensedLarge: () => `
    font-family: 'Roboto Condensed';
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    `,
    jotaOverlineCondensed: () => `
    font-family: 'Roboto Condensed';
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.25rem;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    `,
    jotaHeading01: () => `
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 3.75rem;
    line-height: 4.25rem;
    letter-spacing: 0;
    `,
    jotaHeading02: () => `
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 2.75rem;
    line-height: 3.25rem;
    letter-spacing: 0;
    `,
    jotaHeading03: () => `
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 2.75rem;
    letter-spacing: 0;
    `,
    jotaHeading04: () => `
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.5rem;
    letter-spacing: 0;
    `,
    jotaHeading05: () => `
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: 0;
    `,
    jotaHeading06: () => `
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: 0;
    `,
}

export const MOBILE_SIZE = '820px'
