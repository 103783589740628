import { AppState, MenuState, AppDispatchMenu } from './../types/menu'
import { AnyAction } from 'redux'
import { axiosJOTA, MenuAPI } from '../api'

const initialState: MenuState = {
    isOpen: false,
    activePage: '',
    items: [],
    avatarMenu: {
        isOpen: false,
    },
    searchMenuMobile: {
        isOpen: false,
    },
}

//
// Reducers - Redux Duck Pattern
//
export default (
    state: MenuState = initialState,
    action: AnyAction = { type: '' }
): MenuState => {
    switch (action.type) {
        /**
         * MENU ACTION - Flag
         * Description: Menu is open?
         */
        case 'MENU_ACTION':
            return {
                ...state,
                isOpen: !state.isOpen,
            }

        case 'CHANGE_PAGE':
            return {
                ...state,
                activePage: action.payload,
            }

        case 'GET_ITEMS':
            return {
                ...state,
                items: action.payload,
            }

        case 'CHANGE_AVATAR_MENU':
            return {
                ...state,
                avatarMenu: {
                    ...state.avatarMenu,
                    isOpen:
                        action.payload !== undefined
                            ? action.payload
                            : !state.avatarMenu.isOpen,
                },
            }

        case 'CHANGE_SEARCH_MENU_MOBILE':
            return {
                ...state,
                searchMenuMobile: {
                    ...state.searchMenuMobile,
                    isOpen: action.payload
                        ? action.payload
                        : !state.searchMenuMobile.isOpen,
                },
            }

        default:
            return state
    }
}

//
// Actions - Redux Duck Pattern
//
export type Actions = ReturnType<
    typeof changeMenuAction | typeof changePageAction | typeof getItemsMenu
>

/**
 * Fetch List - Start
 * @return {object}
 */

export const changeMenuAction = () => ({
    type: 'MENU_ACTION',
})

export const changePageAction = (pageDestination: string) => ({
    type: 'CHANGE_PAGE',
    payload: pageDestination,
})

export const getItemsMenu = (items: []) => ({
    type: 'GET_ITEMS',
    payload: items,
})

export const changeAvatarMenu = (status?: boolean) => ({
    type: 'CHANGE_AVATAR_MENU',
    payload: status,
})

export const changeMenuMobile = (status?: boolean) => ({
    type: 'CHANGE_SEARCH_MENU_MOBILE',
    payload: status,
})

//
// Async Opetarions - Redux Duck Pattern
//

/**
 * Change Menu
 * @return {void}
 */
export const changeMenu = () => {
    return async (dispatch: AppDispatchMenu, _getState: () => AppState) => {
        dispatch(changeMenuAction())
    }
}

/**
 * Change Page
 * @param {string} destination
 * @return {void}
 */
export const changePage = (destination: string) => {
    return async (dispatch: AppDispatchMenu, _getState: () => AppState) => {
        dispatch(changePageAction(destination))
    }
}

/**
 * Get Items
 * @return {void}
 */
export const getItems = () => {
    return async (dispatch: AppDispatchMenu, _getState: () => AppState) => {
        const menuAPI = await axiosJOTA.get(`${MenuAPI.get()}`)
        dispatch(getItemsMenu(menuAPI.data))
    }
}

/**
 * Change Avatar Menu
 * @param {boolean} status
 * @return {void}
 */
export const changeAvatarMenuOpen = (status?: boolean) => {
    return async (dispatch: AppDispatchMenu, _getState: () => AppState) => {
        dispatch(changeAvatarMenu(status))
    }
}

/**
 * Change Search Mobile
 * @param {boolean} status
 * @return {void}
 */
export const changeSearchMobileState = (status?: boolean) => {
    return async (dispatch: AppDispatchMenu, _getState: () => AppState) => {
        dispatch(changeMenuMobile(status))
    }
}
