/* eslint-disable no-unused-vars */
import React, { FC, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { changeMenu, changePage, getItems } from './../flux/ducks/menu'
import { AppState } from './../flux/types/menu'
import styled from 'styled-components'
import { MOBILE_SIZE } from './../static/theme'
import { Accordion } from '@JOTAJornalismo/jota-design-system'
import { URLS } from './../utils/constants'
import { useHistory } from 'react-router-dom'
import { colors } from '../static/theme'

// menu icons
import { ReactComponent as HomeIcon } from './../static/icons/menu/home.svg'
import { ReactComponent as TributosAposta } from './../static/icons/menu/tributos-apostas.svg'
import { ReactComponent as TributosDiretoCorte } from './../static/icons/menu/tributos-direto-corte.svg'
import { ReactComponent as TributosDiretoLegislativo } from './../static/icons/menu/tributos-direto-legislativo.svg'
import { ReactComponent as TributosMatinal } from './../static/icons/menu/tributos-matinal.svg'
import { ReactComponent as TributosRelatorioEspecial } from './../static/icons/menu/tributos-relatorio-especial.svg'
import { ReactComponent as DiretoCarf } from './../static/icons/menu/tributos-carf.svg'
import { ReactComponent as CarfInsights } from './../static/icons/menu/tributos-insights-carf.svg'

import { ReactComponent as IconPoderRisco } from './../static/icons/poder/risco.svg'
import { ReactComponent as IconImpacto } from './../static/icons/poder/impacto.svg'
import { changePageNumber } from '../flux/ducks/newsletter'

type MenuProps = {
    menu: any
    roles: any
    itemActive?: string
    changePageMenu: Function
    getItemsMenu: Function
    changeMenuState: Function
    changePageNumberProps: Function
}

type ItemMenu = {
    tributos: {
        root: boolean
        carf: boolean
    }
    poder: any
}

/**
 * MOBILE WIDTH
 * Description: Control width
 */
const MOBILE_WIDTH = 800

/**
 * MENU ID HARDCODED
 */
const menuID = {
    matinal: process.env.REACT_APP_TRIBUTOS_MATINAL ?? '0',
    diretoCorte: process.env.REACT_APP_TRIBUTOS_DIRETOCORTE ?? '0',
    diretoLegislativo: process.env.REACT_APP_TRIBUTOS_DIRETOLEGISLATIVO ?? '0',
    diretoCarf: process.env.REACT_APP_TRIBUTOS_DIRETOCARF ?? '0',
    solucoesCrise: process.env.REACT_APP_TRIBUTOS_SOLUCOES_CRISE ?? '0',
    carfPrevisivel: process.env.REACT_APP_TRIBUTOS_CARFPREVISIVEL ?? '0',
    relatorioEspecial: process.env.REACT_APP_TRIBUTOS_RELATORIOESPECIAL ?? '0',
    apostasSemana: process.env.REACT_APP_TRIBUTOS_APOSTASSEMANA ?? '0',
    carfInsights: process.env.REACT_APP_TRIBUTOS_CARFINSIGHTS ?? '0',
    PODER_apostas: process.env.REACT_APP_PODER_APOSTAS ?? '0',
    PODER_impacto: process.env.REACT_APP_PODER_IMPACTO ?? '0',
    PODER_risco: process.env.REACT_APP_PODER_RISCO ?? '0',
}

const SIZE_SCREEN = 768

/**
 * Menu Component
 * @return {JSX}
 */
const Menu: FC<MenuProps> = ({
    menu,
    roles,
    changePageMenu,
    changeMenuState,
    changePageNumberProps,
}) => {
    /**
     * JOTA PRO - Verticais
     */
    enum VERTICAIS {
        TRIBUTOS = 'tributos',
        PODER = 'poder',
    }

    const history = useHistory()
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: false,
    })

    /**
     * Flag Menu Itens
     * Descrition: Capture the behavior (opened, closed)
     */
    const [itemOpen, setItemOpen] = useState<ItemMenu>({
        tributos: {
            root: true,
            carf: false,
        },
        poder: {
            root: true,
        },
    })

    /**
     * Window Resize
     * Description: Monitor change from window
     */
    useEffect(() => {
        window.addEventListener(
            'resize',
            function () {
                setDimensions({
                    width: window.innerWidth,
                    height:
                        document.getElementById('content')?.clientHeight ??
                        window.innerHeight,
                    isMobile: window.innerWidth < MOBILE_WIDTH ?? false,
                })
            },
            false
        )
    }, [])

    /**
     * Get menu items from API
     */
    useEffect(() => {
        // getItemsMenu()

        // unsubscribe
        return () => {
            setItemOpen({
                ...itemOpen,
                tributos: {
                    ...itemOpen,
                    root: true,
                    carf: true,
                },
            })
        }
    }, [])

    /**
     * Get Accordion
     * Description: Return Accordion Menu
     * @param {any} icon
     * @param {string} destiny
     * @param {string} label
     * @param {"parent" | "child" | "ofChild"} type
     * @param {boolean} rightIcon
     * @param {string} id
     * @param {boolean} _collapsed
     * @return {JSX.Element} Accordion Component
     */
    const getAccordion = (
        icon: any,
        destiny: string,
        label: string,
        type: 'parent' | 'child' | 'ofChild',
        rightIcon: boolean,
        id: string,
        _collapsed?: boolean
    ): JSX.Element => {
        return (
            <Accordion
                LeftIcon={icon}
                onClick={(e: any) => {
                    changePageMenu(id)

                    /** If mobile close menu */
                    if (window.innerWidth <= SIZE_SCREEN) {
                        changeMenuState(false)
                    }

                    if (history) {
                        changePageNumberProps(1)
                        history.push(destiny)
                    }
                    return e
                }}
                label={label}
                type={type}
                RightIcon={rightIcon}
                onlyIcon={!menu.isOpen && dimensions.width > MOBILE_WIDTH}
                id={id}
                active={menu.activePage === id}
            />
        )
    }

    return (
        <>
            <Container isOpen={menu.isOpen} isMobile={dimensions.isMobile}>
                <Accordion
                    active={menu.activePage === 'home'}
                    collapsed={!menu.isOpen}
                    onClick={(e: any) => {
                        changePageMenu('home')

                        /** If mobile close menu */
                        if (window.innerWidth <= SIZE_SCREEN) {
                            changeMenuState(false)
                        }

                        if (history) {
                            changePageNumberProps(1)
                            history.push(URLS.home)
                        }
                        return e
                    }}
                    label='Home'
                    type='parent'
                    LeftIcon={HomeIcon}
                    onlyIcon={!menu.isOpen && dimensions.width > MOBILE_WIDTH}
                    id='home'
                ></Accordion>

                {/* PODER ITEMS */}
                {roles.has_poder && (
                    <Accordion
                        active={menu.activePage === 'poder' && menu.isOpen}
                        collapsed={!menu.isOpen}
                        onClick={(e: any) => {
                            if (menu.isOpen) {
                                setItemOpen({
                                    ...itemOpen,
                                    poder: {
                                        ...itemOpen.poder,
                                        root: !itemOpen.poder.root,
                                    },
                                })
                            }

                            return e
                        }}
                        label='Poder'
                        type='parent'
                        RightIcon={menu.isOpen ? true : false}
                        onlyIcon={false}
                        id='poder'
                    ></Accordion>
                )}

                {roles.has_poder && itemOpen.poder.root && (
                    <AccordionContainer isOpen={menu.isOpen}>
                        {getAccordion(
                            TributosAposta,
                            URLS.newsletter.categoriaSet(
                                VERTICAIS.PODER,
                                parseInt(menuID.PODER_apostas)
                            ),
                            'Apostas da Semana',
                            'child',
                            false,
                            'poder_apostas-da-semana'
                        )}
                        {getAccordion(
                            IconImpacto,
                            URLS.newsletter.categoriaSet(
                                VERTICAIS.PODER,
                                parseInt(menuID.PODER_impacto)
                            ),
                            'Impacto nas Instituições',
                            'child',
                            false,
                            'poder_impacto-nas-instituições'
                        )}
                        {getAccordion(
                            IconPoderRisco,
                            URLS.newsletter.categoriaSet(
                                VERTICAIS.PODER,
                                parseInt(menuID.PODER_risco)
                            ),
                            'Risco Político',
                            'child',
                            false,
                            'poder_risco-político'
                        )}
                    </AccordionContainer>
                )}

                {/* TRIBUTOS ITEMS */}
                {roles.has_tributos && (
                    <Accordion
                        active={menu.activePage === 'tributos' && menu.isOpen}
                        collapsed={!menu.isOpen}
                        onClick={(e: any) => {
                            if (menu.isOpen) {
                                setItemOpen({
                                    ...itemOpen,
                                    tributos: {
                                        ...itemOpen.tributos,
                                        root: !itemOpen.tributos.root,
                                    },
                                })
                            }

                            return e
                        }}
                        label='Tributos'
                        type='parent'
                        RightIcon={menu.isOpen ? true : false}
                        onlyIcon={false}
                        id='tributos'
                    ></Accordion>
                )}
                {roles.has_tributos && itemOpen.tributos.root && (
                    <AccordionContainer isOpen={menu.isOpen}>
                        {getAccordion(
                            TributosAposta,
                            URLS.newsletter.categoriaSet(
                                VERTICAIS.TRIBUTOS,
                                parseInt(menuID.apostasSemana)
                            ),
                            'Apostas da Semana',
                            'child',
                            false,
                            'tributos_apostas-da-semana',
                            !menu.isOpen && dimensions.width > MOBILE_WIDTH
                        )}

                        <>
                            {getAccordion(
                                DiretoCarf,
                                URLS.newsletter.categoriaSet(
                                    VERTICAIS.TRIBUTOS,
                                    parseInt(menuID.diretoCarf)
                                ),
                                'Direto do Carf',
                                'child',
                                false,
                                'tributos_direto-do-carf'
                            )}

                            {getAccordion(
                                CarfInsights,
                                URLS.newsletter.categoriaSet(
                                    VERTICAIS.TRIBUTOS,
                                    parseInt(menuID.carfInsights)
                                ),
                                'Insights do Carf Previsível',
                                'child',
                                false,
                                'tributos_insights-do-carf-previsivel'
                            )}
                        </>

                        {getAccordion(
                            TributosDiretoCorte,
                            URLS.newsletter.categoriaSet(
                                VERTICAIS.TRIBUTOS,
                                parseInt(menuID.diretoCorte)
                            ),
                            'Direto da Corte',
                            'child',
                            false,
                            'tributos_direto-da-corte'
                        )}

                        {getAccordion(
                            TributosDiretoLegislativo,
                            URLS.newsletter.categoriaSet(
                                VERTICAIS.TRIBUTOS,
                                parseInt(menuID.diretoLegislativo)
                            ),
                            'Direto do Legislativo',
                            'child',
                            false,
                            'tributos_direto-do-legislativo'
                        )}

                        {getAccordion(
                            TributosMatinal,
                            URLS.newsletter.categoriaSet(
                                VERTICAIS.TRIBUTOS,
                                parseInt(menuID.matinal)
                            ),
                            'Matinal',
                            'child',
                            false,
                            'tributos_matinal'
                        )}

                        {getAccordion(
                            TributosRelatorioEspecial,
                            URLS.newsletter.categoriaSet(
                                VERTICAIS.TRIBUTOS,
                                parseInt(menuID.relatorioEspecial)
                            ),
                            'Relatório Especial',
                            'child',
                            false,
                            'tributos_relatório-especial'
                        )}
                    </AccordionContainer>
                )}
            </Container>
        </>
    )
}

export const mapStateToProps = (state: AppState) => ({
    menu: state.menu,
    roles: state.auth.roles,
})

export const mapDispatchToProps = (dispatch: any) => ({
    changeMenuState: () => dispatch(changeMenu()),
    changePageMenu: (page: string) => dispatch(changePage(page)),
    getItemsMenu: () => dispatch(getItems()),
    changePageNumberProps: (page: number) => dispatch(changePageNumber(page)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Menu)

const Container = styled.div<{
    isOpen: boolean
    isMobile: boolean
    height: number
}>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    position: fixed;
    width: ${(props) => (props.isOpen ? '20%' : '56px')};
    min-width: ${(props) => (props.isOpen ? '250px' : 'inherit')};
    left: 0px;
    padding-top: 56px;
    background: rgb(245 246 248);
    z-index: 90;
    height: fit-content;
    scrollbar-color: ${colors.transparent.jotaTGray50} transparent;
    scrollbar-width: thin;

    @media (max-width: ${MOBILE_SIZE}) {
        width: 100%;
        padding-bottom: 12px;
        height: auto;
        position: absolute;
        display: ${(props) => (props.isOpen ? 'inherit' : 'none')};
    }
`
const AccordionContainer = styled.div<{ isOpen: boolean }>`
    padding-left: ${(props) => (props.isOpen ? '12px' : '0px')};
    width: 100%;
    box-sizing: border-box;
`
