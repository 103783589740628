import React, { FC } from 'react'
import styled from 'styled-components'
import { colors } from '../../../static/theme'
import { ReactComponent as ArrowImg } from './../../../static/icons/breadcrum-arrow.svg'
import { ReactComponent as ArrowImgReverse } from './../../../static/icons/breadcrumb-reverse.svg'
import { Link, useHistory } from 'react-router-dom'

type BreadcrumbProps = {
    items: BreadcrumbInterface[]
    fromSearch: boolean
}

type BreadcrumbInterface = {
    name: string
    route: string
}

const Breadcrumb: FC<BreadcrumbProps> = ({ items, fromSearch = false }) => {
    const history = useHistory()

    const breadConditional = () => {
        if (!fromSearch) {
            return items.map((item, key) => {
                return (
                    <BreadItem key={key} to={item.route.toLowerCase()}>
                        {item.name}
                        {key < items.length - 1 && (
                            <Arrow fill={colors.black.jotaBlack} />
                        )}
                    </BreadItem>
                )
            })
        } else {
            return (
                <BreadSearch
                    onClick={() => {
                        history.goBack()
                    }}
                >
                    <ArrowInverse fill={colors.black.jotaBlack} />
                    <BreadItemDiv>Voltar aos resultados</BreadItemDiv>
                </BreadSearch>
            )
        }
    }

    return items.length > 0 ? (
        <BreadContainer>{breadConditional()}</BreadContainer>
    ) : null
}

const BreadContainer = styled.ul`
    display: flex;
    list-style: none;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0;
    margin: 0;
`
const BreadItemDiv = styled.div`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: ${colors.gray.jotaGray};
    text-decoration: none;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`

const BreadItem = styled(Link)`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: ${colors.gray.jotaGray};
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`

const Arrow = styled(ArrowImg)`
    margin: 0 10px;
`

const ArrowInverse = styled(ArrowImgReverse)`
    margin: 0 10px;
`

const BreadSearch = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

export default Breadcrumb
