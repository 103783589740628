import React, { useEffect } from 'react'
import Zendesk from 'react-zendesk'
import { colors } from './../static/theme'

declare global {
    // eslint-disable-next-line no-unused-vars
    interface Window {
        zESettings: any
        zE: any
    }
}

/**
 * Zend Desk Component
 * @return {ReactComponent}
 */
const ZendDeskComponent = () => {
    useEffect(() => {
        window.zESettings = {
            webWidget: {
                color: {
                    theme: colors.black.jotaBlack,
                },
            },
        }
    }, [window.zESettings, window.zE, window])

    return (
        <Zendesk defer zendeskKey={process.env.REACT_APP_ZENDESK_API ?? ''} />
    )
}

export default ZendDeskComponent
