import React, { useState, useEffect, ReactElement } from 'react'
import { Input, Button, Tag } from '@JOTAJornalismo/jota-design-system'
import { ReactComponent as ArrowIcon } from '../../static/icons/left-large.svg'
import LogoJota from './../../static/img/jotapro.png'
import { KnowJotaPro } from './../../components'
import './../../styles/pages/forgot-password.scss'
import { connect } from 'react-redux'
import { forgotPassword } from './../../flux/ducks/auth'
import { AppDispatch, AppState } from './../../flux/types/auth'
import { validateEmail } from './../../utils/helpers'
import { URLS } from './../../utils/constants'
import { statusCheck } from './auth-utils'

type Props = {
    authState: string
    changeAuthState: (state: string) => void
    forgotPasswordProps: (email: string) => void
    loading: boolean | null
    error: string
    user: any
    location: any
    history: any
}

/**
 * Cognito Forgot Password
 * Description:
 * Restore user password in two steps:
 * 1 Step - user put the e-mail
 * 2 Step - user receive a message with a link inside body of e-mail
 * 3 Step - After click on this link the user has redirect to route 'reset' where can change e-mail
 * @return {void}
 */
const ForgotPassword: React.FC<Props> = ({
    authState,
    loading,
    error,
    user,
    location,
    history,
    forgotPasswordProps,
}) => {
    const [email, setEmail] = useState<string>('')
    const [errorRedux, setErrorRedux] = useState<boolean | string>(false)
    const [sent, setSent] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean | null>(null)

    /**
     * Redux Events
     * Description: Monitor specific events
     */
    useEffect(() => {
        setIsLoading(loading)

        // Unsubscribe
        return () => {
            setIsLoading(null)
        }
    }, [loading])

    /**
     * Submit Button
     * Fire Cognito Check if e-mail is valid
     * @param {boolean} passEmail - ignore email validation (ex: press enter)
     */
    const onButtonClick = (passEmail = false) => {
        if ((!passEmail && !email) || (!passEmail && !validateEmail(email))) {
            setErrorRedux('Digite um e-mail válido')
            setSent(false)
            return
        }

        setSent(true)
        forgotPasswordProps(email)
    }

    /**
     * sentData
     * Description: Return HTML if data
     * @return {any} HTML Data
     */
    const sentData = (): ReactElement => {
        if (sent) {
            return (
                <React.Fragment>
                    <h1>Confirme a senha</h1>
                    <p>
                        Verifique no seu <strong>e-mail</strong> o link de
                        confirmação, <br /> então visite a página de login
                        novamente.
                    </p>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <h1>Esqueceu a senha?</h1>
                    <p>
                        Entre com seu e-mail cadastrado no{' '}
                        <strong>JOTA PRO.</strong>
                    </p>
                    <div style={{ marginBottom: '24px' }}>
                        <Input
                            startFocus
                            type='email'
                            onBlur={(e: any) => {
                                if (
                                    e.target.value !== '' &&
                                    validateEmail(e.target.value)
                                ) {
                                    setEmail(e.target.value)
                                    setErrorRedux('')
                                } else {
                                    setEmail('')
                                    setErrorRedux('Digite um e-mail válido.')
                                }
                            }}
                            label='e-mail'
                            showPrefixIcon={false}
                            alternativeText={
                                typeof errorRedux === 'string' ? errorRedux : ''
                            }
                            status={statusCheck(errorRedux)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    onButtonClick(true)
                                }
                            }}
                        />
                    </div>
                    <Button
                        label='Enviar Confirmação'
                        size='medium'
                        type='filled'
                        disabled={!email ? true : false}
                        width='100%'
                        onClick={() => onButtonClick()}
                    />
                </React.Fragment>
            )
        }
    }

    return (
        <div className='forgot-password-page'>
            <section className='forgot-password-container'>
                <Tag
                    label='voltar para o login'
                    size='large'
                    type='colored'
                    onClick={() => history.push(URLS.login)}
                    LeftIcon={ArrowIcon}
                />
                <div className='forgot-password-image-container'>
                    <img src={LogoJota} alt='JOTA PRO logo' />
                </div>
                {isLoading ? (
                    <>
                        <h3>Aguarde</h3>
                    </>
                ) : (
                    sentData()
                )}
            </section>

            <KnowJotaPro />
        </div>
    )
}

export const mapStateToProps = (state: AppState) => ({
    user: state.auth.user,
    authState: state.auth.authState,
    loading: state.auth.loading,
    error: state.auth.error,
})

export const mapDispatchToProps = (dispatch: AppDispatch) => ({
    forgotPasswordProps: (email: string) => dispatch(forgotPassword(email)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
