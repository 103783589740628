/* eslint-disable camelcase */
import mixpanel from 'mixpanel-browser'

mixpanel.init(process.env.REACT_APP_MIXPANEL, {
    debug: process.env.REACT_APP_MIXPANEL_DEBUG === 'true' ? true : false,
    ignore_dat: true,
})

const actions = {
    identify: (id) => mixpanel.identify(id),
    alias: (id) => mixpanel.alias(id),
    track: (name, props) => mixpanel.track(name, props),
    people: {
        set: (props) => {
            mixpanel.people.set(props)
        },
    },
}

type MixInterface = {
    id: any
    JOTA_title: string
    JOTA_type: string
    data?: object
    date?: string
    vertical?: object
    user?: any
    company?: any
}

/**
 * MixPanel - Set Data
 * @param {MixInterface} props
 */
export const mixPanelSetData = (props: MixInterface): void => {
    Mixpanel.identify(props.id)
    Mixpanel.track(props.JOTA_title, {
        JOTA_type: props.JOTA_type,
        JOTA_id: props.id,
        ...(typeof props.data === 'object' && props.data),
        ...(typeof props.user === 'object' && props.user),
        ...(typeof props.company === 'object' && props.company),
    })
    Mixpanel.people.set({
        $first_name: props.user && props.user.JOTA_userFirstName,
        $last_name: props.user && props.user.JOTA_userLastName,
        email: props.user && props.user.JOTA_userEmail,
    })
}

export const Mixpanel = actions
