import React, { FC, useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { colors, fonts, MOBILE_SIZE } from './../static/theme'
import { ReactComponent as MenuIcon } from './../static/icons/menu-open.svg'
import { ReactComponent as MenuOpen } from './../static/icons/menu.svg'
import { ReactComponent as SearchIcon } from './../static/icons/search.svg'
import { ReactComponent as OpenNewIcon } from './../static/icons/open-new.svg'
import { ReactComponent as ArrowDownIcon } from './../static/icons/arrow-down.svg'
import { ReactComponent as ArrowUpIcon } from './../static/icons/arrow-up.svg'
import logo from './../static/img/logo-jota-pro.svg'
import {
    Input,
    Button,
    Avatar,
    Tag,
    Accordion,
} from '@JOTAJornalismo/jota-design-system'
import { AppState } from './../flux/types/auth'
import { connect } from 'react-redux'
import { URLS } from './../utils/constants'
import {
    changeAvatarMenuOpen,
    changeMenu,
    changeSearchMobileState,
} from './../flux/ducks/menu'
import { AppDispatchMenu } from './..//flux/types/menu'
import { withRouter, useHistory } from 'react-router-dom'
import { ReactComponent as LogoutIcon } from './../static/icons/menu/logout.svg'
import { ReactComponent as UserIcon } from './../static/icons/menu/user.svg'
import { mixPanelSetData } from './../utils/mixpanel'
import { Auth } from 'aws-amplify'
import { ZendDeskComponent } from '.'
import { profileGetUser } from './../flux/ducks/auth'

type HeaderInterface = {
    user: any
    term: string | undefined
    menu: any
    changeMenuProps: Function
    changeAvatarMenuProps: Function
    changeSearchMobileStateRedux: Function
    profileGetUserProps: Function
    location: any
    avatar: any
    searchMenu: any
    userBO: any
}

const SIZE_SCREEN = 768

const Header: FC<HeaderInterface> = ({
    user,
    term = '',
    menu,
    changeMenuProps,
    changeAvatarMenuProps,
    changeSearchMobileStateRedux,
    profileGetUserProps,
    location,
    avatar,
    searchMenu,
    userBO,
}) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [showSearchInput, setShowSearchInput] = useState<boolean>(false)
    const [searchTerm, setSeachTerm] = useState<string | undefined>(term)
    const [clearSearch, setClearSearch] = useState<boolean>(false)
    const history = useHistory()
    const HeaderRef = useRef<HTMLDivElement>()
    const MenuAvatarRef = useRef<HTMLDivElement>()

    const handleResize = () => {
        setWindowWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', () => handleResize())
        profileGetUserProps()
    }, [])

    useEffect(() => {
        setSeachTerm(term)

        return () => {
            setSeachTerm('')
        }
    }, [term])

    useEffect(() => {
        if (!location.pathname.includes('pesquisar')) {
            setSeachTerm('')
            setClearSearch(true)
        }

        return () => {
            setSeachTerm('')
            setClearSearch(true)
        }
    }, [location.pathname])

    /** HOOK - click outsided */
    useEffect(() => {
        /**
         * handleClickOutside
         * @param {any} event
         * @return {void}
         */
        function handleClickOutside(event: any) {
            if (
                avatar.isOpen &&
                HeaderRef.current &&
                !HeaderRef.current.contains(event.target) &&
                !MenuAvatarRef.current?.contains(event.target)
            ) {
                changeAvatarMenuProps(false)
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [HeaderRef, avatar])

    /**
     * Redirect JOTA.INFO
     */
    const redirectToJotaInfo = async () => {
        const userCog = await Auth.currentAuthenticatedUser()
        const URL_JOTA_PRO = `${process.env.REACT_APP_LOGIN_URL}/`
        const ID_TOKEN = userCog.signInUserSession.idToken.jwtToken
        const URL_DESTINATION = `${URL_JOTA_PRO}?&id_token=${ID_TOKEN}`

        mixPanelSetData({
            id: user && user.username,
            JOTA_title: `JOTA INFO - CTA`,
            JOTA_type: 'JOTA INFO - CTA',
            user: user && user.username,
            company: {
                JOTA_companyField: userBO.profile.company_field,
                JOTA_companyName: userBO.profile.company_name,
                JOTA_companyTier: userBO.profile.company_tier,
                JOTA_companyType: userBO.profile.company_type,
                JOTA_companyWorkersCount: userBO.profile.company_workers_count,
            },
        })

        window.open(URL_DESTINATION)
    }

    /**
     * On Submit
     * @param {React} e
     */
    const onSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
        /**
         * Enter Event
         */
        if (e.key === 'Enter') {
            const value = e.currentTarget.value

            if (value) {
                history.push(URLS.newsletter.searchSet(value))
            }
        }
    }

    return (
        <>
            <Container>
                <ZendDeskComponent />
                <RightContainer>
                    <IconContainer onClick={() => changeMenuProps()}>
                        {menu.isOpen ? (
                            <MenuIcon fill={colors.white.jotaWhite} />
                        ) : (
                            <MenuOpen fill={colors.white.jotaWhite} />
                        )}
                    </IconContainer>
                    <Logo
                        onClick={() => {
                            history.push(URLS.root)
                            history.go(0)
                        }}
                    >
                        <img src={logo} />
                        <div style={{ color: 'white' }}></div>
                    </Logo>
                </RightContainer>
                <LeftContainer>
                    <InputContainer
                        showSearchInput={showSearchInput}
                        searchMenuIsOpen={searchMenu.isOpen}
                    >
                        {windowWidth > SIZE_SCREEN ? (
                            <>
                                <Input
                                    clearContent={{
                                        data: clearSearch,
                                        fn: setClearSearch,
                                    }}
                                    defaultValue={searchTerm}
                                    type='text'
                                    IconLeft={SearchIcon}
                                    placeholder='Buscar no JOTA PRO'
                                    width='260px'
                                    onKeyDown={(e) => onSubmit(e)}
                                />
                            </>
                        ) : (
                            <SearchIcon
                                fill={colors.white.jotaWhite}
                                onClick={() => {
                                    changeSearchMobileStateRedux()

                                    if (window.innerWidth <= SIZE_SCREEN) {
                                        // changeSearchMobileStateRedux()

                                        if (avatar.isOpen) {
                                            changeAvatarMenuProps(false)
                                        }
                                    }
                                    setShowSearchInput(!showSearchInput)
                                }}
                            />
                        )}
                    </InputContainer>

                    {/*
                        User Container
                        Avatar + Nickname
                    */}
                    <UserContainer
                        id='avatar-root'
                        ref={MenuAvatarRef}
                        onClick={() => {
                            /**
                             * Mobile
                             */
                            if (window.innerWidth <= SIZE_SCREEN) {
                                if (searchMenu.isOpen) {
                                    changeSearchMobileStateRedux(false)
                                } else {
                                    setShowSearchInput(false)
                                }
                                changeAvatarMenuProps()
                            } else {
                                /**
                                 * Desktop
                                 */
                                setShowSearchInput(!showSearchInput)
                                setShowSearchInput(false)
                                changeAvatarMenuProps()
                            }
                        }}
                    >
                        <AvatarContainer showUserMenu={avatar.isOpen}>
                            <Avatar size='32' />
                        </AvatarContainer>
                        <Tag
                            size='medium'
                            label={
                                windowWidth > SIZE_SCREEN &&
                                userBO &&
                                userBO.first_name
                            }
                            color='white'
                            RightIcon={
                                avatar.isOpen ? ArrowUpIcon : ArrowDownIcon
                            }
                        />
                    </UserContainer>
                </LeftContainer>
            </Container>
            {windowWidth <= SIZE_SCREEN && searchMenu.isOpen ? (
                <MobileInputContainer>
                    <Input
                        type='text'
                        placeholder='Buscar no JOTA PRO'
                        clearContent
                        IconLeft={SearchIcon}
                        width='100%'
                        onKeyDown={(e) => onSubmit(e)}
                    />
                </MobileInputContainer>
            ) : (
                ''
            )}
            {avatar.isOpen && (
                <UserMenuContainer ref={HeaderRef} id='avatar-menu'>
                    <Button
                        label='JOTA Info'
                        size='small'
                        type='filled'
                        LastIcon={OpenNewIcon}
                        onClick={() => redirectToJotaInfo()}
                        width='100%'
                    />
                    <UserNameContainer>
                        <UserName>
                            {userBO &&
                                userBO.first_name.toUpperCase() +
                                    ' ' +
                                    userBO.last_name.toUpperCase()}
                        </UserName>
                        <Tag
                            size='medium'
                            label='PRO'
                            color='black'
                            type='filled'
                            disableHover
                        />
                    </UserNameContainer>

                    <UserEmail>
                        {user && user.attributes && user.attributes.email}
                    </UserEmail>

                    <LogoutContainer>
                        <Accordion
                            LeftIcon={UserIcon}
                            onClick={(e: any) => {
                                mixPanelSetData({
                                    id: user && user.username,
                                    JOTA_title: `Configurações da Conta`,
                                    JOTA_type: 'configuracoes',
                                    data: {
                                        JOTAuserFirstName:
                                            user && user.username,
                                    },
                                })
                                changeAvatarMenuProps(false)
                                history.push(URLS.profile)

                                return e
                            }}
                            label='Configurações da Conta'
                            type='child'
                            RightIcon={false}
                            onlyIcon={false}
                            id='configuracoes-button'
                        />
                        <Accordion
                            LeftIcon={LogoutIcon}
                            onClick={(e: any) => {
                                mixPanelSetData({
                                    id: user && user.username,
                                    JOTA_title: `Logout`,
                                    JOTA_type: 'logout',
                                    data: {
                                        JOTAuserFirstName:
                                            user && user.username,
                                    },
                                })
                                history.push(URLS.logout)

                                return e
                            }}
                            label='Logout'
                            type='child'
                            RightIcon={false}
                            onlyIcon={false}
                            id='logout-button'
                        />
                    </LogoutContainer>
                </UserMenuContainer>
            )}
        </>
    )
}
export const mapStateToProps = (state: AppState) => ({
    user: state.auth.user,
    menu: state.menu,
    avatar: state.menu.avatarMenu,
    searchMenu: state.menu.searchMenuMobile,
    userBO: state.auth.userBO,
})

export const mapDispatchToProps = (dispatch: AppDispatchMenu) => ({
    changeMenuProps: () => dispatch(changeMenu()),
    changeAvatarMenuProps: (status?: boolean) =>
        dispatch(changeAvatarMenuOpen(status)),
    changeSearchMobileStateRedux: (status?: boolean) =>
        dispatch(changeSearchMobileState(status)),
    profileGetUserProps: () => dispatch(profileGetUser()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))

const Container = styled.div`
    width: 100%;
    height: 56px;
    background: ${colors.black.jotaBlackLight};
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 91;
`
const RightContainer = styled.div`
    display: flex;
    align-items: center;
`
const IconContainer = styled.div`
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`
const Logo = styled.div`
    margin-left: 12px;
    display: inline-flex;
    cursor: pointer;
`
const LeftContainer = styled.div`
    display: flex;
    align-items: center;
`

const snippedCodeSearhInputValue = (
    searchMenuIsOpen: boolean
): string | void => {
    if (searchMenuIsOpen) {
        return `
        &::before {
            content: '';
            position: absolute;
            width: 12px;
            height: 12px;
            top: 50px;
            background-color: ${colors.gray.jotaGrayDarker};
            transform: rotate(45deg);
        }`
    }
}

const InputContainer = styled.div<{
    searchMenuIsOpen: boolean
    showSearchInput: boolean
}>`
    margin-right: 12px;
    max-width: 260px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${(props) => snippedCodeSearhInputValue(props.searchMenuIsOpen)}
`
const UserContainer = styled.div`
    margin-right: 12px;
    display: flex;
    cursor: pointer;
    z-index: 95;
`
const SnippedCodeShowUserMenu = `
    &::before {
            content: '';
            position: absolute;
            width: 12px;
            height: 12px;
            top: 50px;
            background-color: #eff0f4;
            transform: rotate(45deg);
        }

    @media (max-width: 768px) {
        &::before {
            content: '';
            position: absolute;
            width: 12px;
            height: 12px;
            top: 50px;
            background-color: #eff0f4;
            transform: rotate(45deg);
        }
    };
`

const AvatarContainer = styled.div<{ showUserMenu }>`
    margin-right: 6px;
    display: flex;
    justify-content: center;

    ${(props) => props.showUserMenu && SnippedCodeShowUserMenu}
`
const MobileInputContainer = styled.div`
    background: ${colors.gray.jotaGrayDarker};
    margin-top: 56px;
    padding: 6px;
    position: fixed;
    width: 100%;
    box-sizing: border-box;
    z-index: 95;
`
const UserMenuContainer = styled.div`
    background-color: #eff0f4;
    position: fixed;
    padding: 12px;
    right: 0;
    top: 56px;

    @media (max-width: ${MOBILE_SIZE}) {
        width: 100%;
        box-sizing: border-box;
        left: 0;
        z-index: 95;
    } ;
`
const UserNameContainer = styled.div`
    display: flex;
    margin: 10px 0 2px 0;
`
const UserName = styled.p`
    ${fonts.jotaBody()};
    font-weight: 700;
    color: ${colors.black.jotaBlackLight};
    margin: 0 6px 0 0;
`
const LogoutContainer = styled.div`
    width: 120%;
    margin: 0 -12px -12px -12px;
`
const UserEmail = styled.p`
    ${fonts.jotaBodyXSmall()};
    color: ${colors.gray.jotaGrayDark};
    margin: 0;
    margin-bottom: 10px;
`
