import React, { FC, useState, useEffect } from 'react'
import styled from 'styled-components'
import Header from '../components/header'
import { MenuComponent } from '../components'
import { fonts, colors } from '../static/theme'
import img from '../static/img/404.png'
import { MOBILE_SIZE } from 'src/static/theme'
import { Card, Dialog } from '@JOTAJornalismo/jota-design-system'
import { colorByVertical, dateConvert } from 'src/utils/helpers'
import { URLS, BACKOFFICE_RESPONSES, ERROR } from 'src/utils/constants'
import { LastFourNewsLoading } from 'src/utils/loading/newsletters-content'
import { withRouter, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { getContent5Last } from 'src/flux/ducks/newsletter'

type PageNotFoundProps = {
    lastNews: any
    lastNewsStatus: string
    getLastFiveNews: Function
    menu: any
}

const PageNotFound: FC<PageNotFoundProps> = ({
    lastNews,
    lastNewsStatus,
    getLastFiveNews,
    menu,
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const history = useHistory()

    useEffect(() => {
        setIsLoading(lastNews.loading)
    }, [lastNews.loading])

    useEffect(() => {
        getLastFiveNews()
    }, [])

    const returnLastFourPublishedContent = (): any => {
        if (!isLoading) {
            if (lastNews && lastNews.items && lastNews.items.length > 0) {
                const filteredFourNews = lastNews.items.filter(
                    (item, index) => index < 4
                )
                return filteredFourNews.map((item: any, key: number) => {
                    return (
                        <Card
                            key={key}
                            subjectTagColor={colorByVertical(
                                item.category.vertical.name.toLowerCase()
                            )}
                            subjectTagLabel={item.category.vertical.name}
                            subjectTagType='filled'
                            subjectTagDisableHover
                            newsLink={URLS.newsletter.conteudoSet(
                                item.category.vertical.name.toLowerCase(),
                                item.id,
                                false
                            )}
                            size='xsmall'
                            title={item && item.title}
                            authorTagColor='gray'
                            authorTagLabel={dateConvert(item.send_time).date}
                            relatedTagColor='gray'
                            relatedTagLabel={dateConvert(item.send_time).time}
                            tagColor={colorByVertical(
                                item.category.vertical.name.toLowerCase()
                            )}
                            TagLeftIcon={
                                item && item.category && item.category.icon
                            }
                            tagLabel={
                                item && item.category && item.category.name
                            }
                            tagOnClick={() =>
                                history.push(
                                    `${URLS.newsletter.categoriaSet(
                                        item.category.vertical.name.toLowerCase(),
                                        item.category.id
                                    )}`
                                )
                            }
                            relatedTagDisableHover
                            authorTagDisableHover
                            titleSearchTerm=''
                        />
                    )
                })
            } else {
                if (lastNewsStatus === ERROR) {
                    return (
                        <div>
                            <Dialog
                                message={
                                    BACKOFFICE_RESPONSES.NO_ACCESS_VERTICAL
                                }
                                type={ERROR}
                            />
                        </div>
                    )
                }
            }
        } else {
            return <LastFourNewsLoading />
        }
    }

    return (
        <>
            <Header />
            <Container>
                <MenuComponent />
                <NotFound menuIsOpen={menu.isOpen}>
                    <TextContainer>
                        <Title>Ooops!</Title>
                        <SubTitle>Conteúdo não encontrado.</SubTitle>
                        <Paragraph style={{ margin: '0 0 6px 0' }}>
                            O sistema pode não ter localizado o que você
                            procurava, mas as instituições continuam funcionando
                            normalmente.
                        </Paragraph>
                        <Paragraph style={{ margin: '6px 0 0 0' }}>
                            Experimente fazer uma busca ou{' '}
                            <Link href='/'>voltar para a página inicial</Link>.
                        </Paragraph>
                    </TextContainer>
                    <CardContainerTitle>
                        Últimos conteúdos publicados
                    </CardContainerTitle>
                    <CardContainer>
                        {returnLastFourPublishedContent()}
                    </CardContainer>
                </NotFound>
            </Container>
        </>
    )
}

const mapStateToProps = (state: any) => ({
    loading: state.newsletters.content.loading,
    lastNews: state.newsletters.content.ultimas,
    lastNewsStatus: state.newsletters.content.ultimas.status,
    menu: state.menu,
})

const mapDispatchToProps = (dispatch: any) => ({
    getLastFiveNews: () => dispatch(getContent5Last()),
})

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PageNotFound)
)

const Container = styled.div<{ menuIsOpen: boolean }>`
    display: flex;
`
const NotFound = styled.section<{ menuIsOpen: boolean }>`
    margin-top: 56px;
    padding: 44px;
    width: ${(props) => (props.menuIsOpen ? '80%' : 'calc(100% - 56px);')};
    margin-left: ${(props) => (props.menuIsOpen ? '20%' : '3%')};

    @media (max-width: ${MOBILE_SIZE}) {
        padding: 18px;
        margin-left: 3%;
    }
`
const TextContainer = styled.div`
    margin: 0;
    background: url(${img}) no-repeat;
    background-size: contain;
    padding-left: 195px;

    @media (max-width: ${MOBILE_SIZE}) {
        padding-left: 0;
        padding-top: 176px;
    }
`
const Title = styled.h1`
    ${fonts.jotaHeading01()};
    color: ${colors.black.jotaBlack};
    margin: 0 0 6px 0;
`
const SubTitle = styled.h1`
    ${fonts.jotaHeading02()};
    color: ${colors.black.jotaBlack};
    margin: 6px 0 36px 0;
`
const Paragraph = styled.p`
    ${fonts.jotaBodyLarge()};
    color: ${colors.gray.jotaGrayDarker};
`
const Link = styled.a`
    ${fonts.jotaBodyLarge()};
    font-weight: 700;
    color: ${colors.black.jotaBlack};
    cursor: pointer;
`
const CardContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 36px;
    border-top: 1px solid ${colors.gray.jotaGrayLighter};
    padding-top: 36px;

    @media (max-width: ${MOBILE_SIZE}) {
        grid-template-columns: 1fr;
    }
`
const CardContainerTitle = styled.h2`
    ${fonts.jotaHeading05()};
    color: ${colors.black.jotaBlack};
    padding-top: 44px;
    margin-top: 0;
`
