import Amplify, { Auth } from 'aws-amplify'
import { awsconfig } from './amplify'
import { AppState, AppDispatch, AuthState } from './../types/auth'
import { AnyAction } from 'redux'
import { CognitoUser } from '@aws-amplify/auth'
import { decodeJWT } from './../../utils/helpers'
import { axiosJOTA, ProfileAPI } from './../api'
import { mixPanelSetData } from './../../utils/mixpanel'

Amplify.configure(awsconfig)

/**
 * Redux Duck Pattern
 * Authorization
 */

const initialState: AuthState = {
    authState: 'signIn',
    user: null,
    email: '',
    error: '',
    loading: null,
    obs: '',
    statusPassword: false,
    roles: {},
    token: null,
    userBO: null,
    firstLogin: false,
}

//
// Reducers - Redux Duck Pattern
//
export default (
    state: AuthState = initialState,
    action: AnyAction = { type: '' }
): AuthState => {
    switch (action.type) {
        /**
         * Auth System Error
         * Description: Error on Cognito Authentication
         */
        case 'AUTH_SYSTEM_ERROR':
            return {
                ..._getCommonState(state),
                error: action.payload,
                loading: false,
            }

        /**
         * Auth Begin Loading
         * Description: Starts process of authentication on Cognito set loading is true
         */
        case 'AUTH_BEGIN_LOADING':
            return {
                ..._getCommonState(state),
                loading: true,
            }

        /**
         * Auth Init
         * Description: Start auth with any error
         */
        case 'AUTH_INIT':
            return {
                ...initialState,
                error: action.payload,
            }

        /**
         * Auth Change Auth State
         * Description: Detect any change of user or auth from Cognito
         */
        case 'AUTH_CHANGE_AUTH_STATE':
            return {
                ..._getCommonState(state),
                authState: action.payload,
            }

        /**
         * Auth Fetch authed User
         * Description: Get user authenticated on Cognito
         */
        case 'AUTH_FETCH_AUTHED_USER':
            axiosJOTA.defaults.headers.common[
                'Authorization'
            ] = `Bearer ${action.payload.signInUserSession.idToken.jwtToken}`

            // User Roles
            const roles =
                JSON.parse(
                    decodeJWT(action.payload.signInUserSession.idToken.jwtToken)
                        .permissions
                ) ?? null

            // Check if user has flags Tributos and Poder - Logout
            if (!roles.has_tributos && !roles.has_poder) {
                Auth.signOut()
                return {
                    authState: 'signIn',
                    user: null,
                    email: '',
                    error: 'no-user-access',
                    loading: null,
                    obs: '',
                    statusPassword: false,
                    roles: {},
                    token: null,
                    userBO: null,
                    firstLogin: false,
                }
            }

            return {
                ..._getCommonState(state),
                user: action.payload,
                loading: false,
                token: action.payload.signInUserSession.idToken.jwtToken,
                roles: roles,
            }
        /**
         * Auth Sign In Success
         * Description: Auth Success - receive id token
         */
        case 'AUTH_SIGN_IN_SUCCESS':
            return {
                ..._getCommonState(state),
                user: action.payload,
                authState: '',
                loading: false,
                token: action.payload.signInUserSession.idToken.jwtToken,
            }
        /**
         * Auth Forgot Password Success
         */
        case 'AUTH_FORGOT_PASSWORD_SUCCESS':
            return {
                ..._getCommonState(state),
                authState: 'forgotPasswordReset',
                email: action.payload,
            }

        /**
         * Auth Forgot Password Error
         */
        case 'AUTH_FORGOT_PASSWORD_ERROR':
            return {
                ..._getCommonState(state),
                statusPassword: false,
                error: action.payload,
                loading: false,
            }

        /**
         * User Backoffice Update
         */
        case 'USER_BO_UPDATE':
            return {
                ..._getCommonState(state),
                userBO: action.payload,
            }

        case 'AUTH_FIRST_LOGIN':
            return {
                ..._getCommonState(state),
                firstLogin: true,
            }

        case 'USER_UPDATE_DATA':
            return {
                ..._getCommonState(state),
                userBO: {
                    ...state.userBO,
                    first_name: action.payload.givenName,
                    last_name: action.payload.familyName,
                },
            }
        default:
            return state
    }
}

const _getCommonState = (state: AuthState) => ({
    ...state,
    error: '',
    loading: false,
})

//
// Actions - Redux Duck Pattern
//
export type Actions = ReturnType<
    | typeof fetchAuthedUserSuccess
    | typeof authInit
    | typeof authBeginLoading
    | typeof authError
    | typeof changeAuthState
    | typeof authSignInSuccess
    | typeof authForgotPasswordSuccess
>

/**
 * Auth Forgot Password Success
 * @param {CognitoUser} user
 * @return {object} user
 */
export const fetchAuthedUserSuccess = (user: CognitoUser) => ({
    type: 'AUTH_FETCH_AUTHED_USER',
    payload: user,
})

/**
 * Auth Init
 * @param {string} status
 * @return {object}
 */
export const authInit = (status: string) => ({
    type: 'AUTH_INIT',
    payload: status,
})

/**
 * Auth Begin Loading
 * @return {object}
 */
export const authBeginLoading = () => ({
    type: 'AUTH_BEGIN_LOADING',
})

/**
 * Auth Error
 * @param {any} err
 * @return {object}
 */
export const authError = (err: any) => ({
    type: 'AUTH_SYSTEM_ERROR',
    payload: err,
})

/**
 * Change Auth State
 * @param {string} value
 * @return {object}
 */
export const changeAuthState = (value: string) => ({
    type: 'AUTH_CHANGE_AUTH_STATE',
    payload: value,
})

/**
 * Auth Sign In - Success
 * @param {CognitoUser} user
 * @return {object}
 */
export const authSignInSuccess = (user: CognitoUser) => ({
    type: 'AUTH_SIGN_IN_SUCCESS',
    payload: user,
})

/**
 * Auth Forgot Password - Success
 * @param {string} email
 * @return {object} payload
 */
export const authForgotPasswordSuccess = (email: string) => ({
    type: 'AUTH_FORGOT_PASSWORD_SUCCESS',
    payload: email,
})

/**
 * Forgot Passowrd Change - Success
 * @return {object}
 */
export const forgotPasswordChangeSuccess = () => ({
    type: 'AUTH_FORGOT_PASSWORD_SUCCESS',
    payload: 'verificar',
})

/**
 * Forgot Password Change Error
 * @param {any} err
 * @return {object}
 */
export const forgotPasswordChangeError = (err: any) => ({
    type: 'AUTH_FORGOT_PASSWORD_ERROR',
    payload: err,
})

/**
 * Update User from Backoffice
 * @param {any} user
 * @return {object}
 */
export const updateUserBO = (user: any) => ({
    type: 'USER_BO_UPDATE',
    payload: user,
})

/**
 * Cognito - First Login
 * @param {string} email
 * @param {string} password
 * @return {void}
 */
export const authFirstLogin = () => ({
    type: 'AUTH_FIRST_LOGIN',
})

export const authFirstLoginSuccess = () => ({
    type: 'AUTH_FIRST_LOGIN_SUCCESS',
})
export const updateNameData = (user: USERINTERFACE) => ({
    type: 'USER_UPDATE_DATA',
    payload: user,
})

//
// Async Opetarions - Redux Duck Pattern
//

/**
 * Refresh Token - Cognito
 * @return {void}
 */
export const refreshToken = () => {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
        try {
            const cognitoUser = await Auth.currentAuthenticatedUser()
            const currentSession = await Auth.currentSession()

            cognitoUser.refreshSession(
                currentSession.getRefreshToken(),
                (err: any, session: any) => {
                    // console.log('ERROR refresh token -> -> ', err, session)
                }
            )
        } catch (err) {
            dispatch(authError(err))
        }
    }
}

/**
 * Fetch Autheticated User
 * @return {void}
 */
export const fetchAuthedUser = () => {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
        dispatch(authBeginLoading())
        try {
            const user = await Auth.currentAuthenticatedUser()
            dispatch(fetchAuthedUserSuccess(user))
        } catch (error: any) {
            dispatch(authInit('NOT_LOGGED'))
        }
    }
}

/**
 * Sign Out - Logout
 * @return {void}
 */
export const signOut = () => {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
        dispatch(authInit(''))
        try {
            await Auth.signOut()
        } catch (err) {
            dispatch(authError(err))
        }
    }
}

/**
 * Sign In - Login
 * @param {string} email
 * @param {string} password
 * @param {string} newPassword - First Login
 * @return {void}
 */
export const signIn = (
    email: string,
    password: string,
    newPassword?: string
) => {
    return async (dispatch: AppDispatch, getState?: () => AppState) => {
        dispatch(authBeginLoading())
        try {
            const user = await Auth.signIn(email, password)

            // First Login - Flag Cognito
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                // has new password will change
                if (newPassword) {
                    const userChange = await Auth.completeNewPassword(
                        user,
                        newPassword
                    )
                    dispatch(authSignInSuccess(userChange))
                }

                // fire first login redux state
                else {
                    dispatch(authFirstLogin())
                }
            }

            mixPanelSetData({
                id: user && user.username,
                JOTA_title: `Login`,
                JOTA_type: 'login',
                user: {
                    JOTA_userEmail:
                        user && user.attributes && user.attributes.email,
                    JOTA_userFirstName:
                        user && user.attributes && user.attributes.given_name,
                    JOTA_userLastName:
                        user && user.attributes && user.attributes.family_name,
                },
            })

            dispatch(authSignInSuccess(user))
        } catch (err: any) {
            dispatch(authError(err.message))
        }
    }
}

/**
 * Forgot Password
 * @param {string} email
 * @return {void}
 */
export const forgotPassword = (email: string) => {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
        dispatch(authBeginLoading())
        try {
            await axiosJOTA.post(ProfileAPI.resetPassword(email))
            dispatch(authForgotPasswordSuccess(email))
        } catch (err) {
            dispatch(authError(err))
        }
    }
}

/**
 * Forgot Password Submit
 * Description: After send a forgot password user receive (by email) a code that it's necessary validate
 * @param {string} email
 * @param {string} code
 * @param {string} password
 * @return {void}
 */
export const forgotPasswordSubmit = (
    email: string,
    code: string,
    password: string
) => {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
        dispatch(authBeginLoading())

        mixPanelSetData({
            id: email,
            JOTA_title: `Esqueceu a Senha`,
            JOTA_type: 'esqueceu a senha',
        })

        try {
            await Auth.forgotPasswordSubmit(email, code, password)
            dispatch(forgotPasswordChangeSuccess())
        } catch (err: any) {
            dispatch(forgotPasswordChangeError(err.log))
        }
    }
}

/**
 * Profile Get User
 * Description: Get user data from API on Backoffice
 * @return {void}
 */
export const profileGetUser = () => {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
        try {
            const userData = await axiosJOTA.get(ProfileAPI.me())
            dispatch(updateUserBO(userData.data))
        } catch (err: any) {
            return false
        }
    }
}

export interface USERINTERFACE {
    familyName: string
    givenName: string
}

/**
 * Set Profile Data
 * Description: Change user (given and family) name data
 * @param {USERINTERFACE} user
 * @return {void}
 */
export const setProfileData = (user: USERINTERFACE) => {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
        try {
            dispatch(updateNameData(user))
        } catch (err: any) {
            return false
        }
    }
}
