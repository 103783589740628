/**
 * Alternative Text
 * @param {object} element
 * @return {string}
 */
export const alternativeText = (element: {}): string => {
    if (typeof element === 'string') {
        return element
    } else {
        return ''
    }
}

/**
 * Status
 * @param {object} element
 * @return {string}
 */
export const statusCheck = (element: {}):
    | 'error'
    | 'normal'
    | 'disabled'
    | 'success'
    | undefined => {
    if (element === false) {
        return 'normal'
    } else if (element === '') {
        return 'normal'
    } else {
        return 'error'
    }
}
