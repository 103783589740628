import React, { FC, useEffect, useRef, useState } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { AppState, AppDispatch } from '../flux/types/auth'
import { fetchAuthedUser, refreshToken, signOut } from '../flux/ducks/auth'
import { URLS } from './constants'
import { Loading } from './../components'

type Props = {
    user: any | null
    fetchAuthedUserState: () => void
    refreshToken: () => void
    signOut: () => void
    path: string
    component?: React.ReactNode
    exact?: any
    loading: boolean | null
    roles: any
    error: string
    redirectTo?: string
}

/**
 * Check Redirect Words to Avoid
 */
const checkRedirectWords = () => {
    if (
        location.pathname !== '' &&
        location.pathname !== 'logout' &&
        location.pathname !== '/' &&
        !location.pathname.includes('sso') &&
        !location.pathname.includes('logout') &&
        !location.pathname.includes('login')
    ) {
        localStorage.setItem('JOTAPRO-redirect', location.pathname)
    }
}

/**
 * Check Private Routes
 * Description: This Private Route will check if user is authenticated
 * @param {object} Props
 * @return {Route} Route Component

 */
const PrivateRoute: FC<Props> = ({
    user,
    fetchAuthedUserState,
    path,
    component,
    exact,
    error,
    redirectTo,
}) => {
    const isFirstRef = useRef(true)
    const [startLoading, setStartLoading] = useState<boolean>(true)

    useEffect(() => {
        if (isFirstRef.current) {
            isFirstRef.current = false
            fetchAuthedUserState()
        }
    }, [])

    useEffect(() => {
        if (user !== null || error === 'NOT_LOGGED') {
            setStartLoading(false)
        }
    }, [user, error])

    /**
     * Redirect Route Check
     * Description:
     * @return {ReactElement}
     */
    const redirectRouteCheck = () => {
        if (user) {
            if (!redirectTo) {
                return <Route exact={exact} path={path} component={component} />
            } else {
                return <Redirect to={redirectTo} />
            }
        } else {
            checkRedirectWords()
            return <Redirect to={URLS.login} />
        }
    }

    /*
     * Login for Authentication
     * Start in loading
     * Wait and check if user has credentials
     * YES - Redirect to component props
     * NO - Redirect to login route
     */
    return startLoading ? <Loading /> : redirectRouteCheck()
}

export const mapStateToProps = (state: AppState) => ({
    user: state.auth.user,
    authState: state.auth.authState,
    loading: state.auth.loading,
    roles: state.auth.roles,
    error: state.auth.error,
})

export const mapDispatchToProps = (dispatch: AppDispatch) => ({
    fetchAuthedUserState: () => dispatch(fetchAuthedUser()),
    refreshToken: () => dispatch(refreshToken()),
    signOut: () => dispatch(signOut()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
