import React, { FC, useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import LogoJota from './../static/img/jotapro.png'
import { colors, fonts } from './../static/theme'
import { Button } from '@JOTAJornalismo/jota-design-system'

type ModalProps = {
    title?: string
    text: string
    moreLink?: string
}

const Modal: FC<ModalProps> = ({ title = '', text, moreLink = '' }) => {
    const [show, setShow] = useState<boolean>(false)

    useEffect(() => {
        if (
            !localStorage.getItem('JOTAPRO-tour') ||
            localStorage.getItem('JOTAPRO-tour') !== 'false'
        ) {
            setShow(true)
        }
    }, [])

    return (
        <>
            {show && (
                <ModalBase id='modal-root'>
                    <ModalContainer>
                        <LogoJotaImg src={LogoJota} />
                        <Title>{title}</Title>
                        <Text>
                            {text}{' '}
                            {moreLink && (
                                <Link href={moreLink} target='_blank'>
                                    SAIBA MAIS{' '}
                                </Link>
                            )}
                        </Text>

                        <Row>
                            <Button
                                data-testid='btnOK'
                                label='Fechar'
                                size='medium'
                                type='filled'
                                onClick={(e) => {
                                    localStorage.setItem(
                                        'JOTAPRO-tour',
                                        'false'
                                    )
                                    setShow(false)
                                }}
                            />
                        </Row>
                    </ModalContainer>
                </ModalBase>
            )}
        </>
    )
}

export default Modal

const showBaseModal = keyframes`
    0% { opacity: 0; top: -100px; position: relative; }
    100% { opacity:1; top: 0px; position: relative; }
`

const ModalBase = styled.div`
    background:#08080899;
    position:absolute;
    display: flex;
    z-index: 92;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;

}
`

const ModalContainer = styled.div`
    background: white;
    max-width: 400px;
    padding: 2em;
    box-shadow: 4px 7px 2px rgb(0 0 0 / 12%);
    animation-name: ${showBaseModal};
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    border-radius: 4px;
`

const LogoJotaImg = styled.img`
    max-width: 200px;
`

const Title = styled.h1`
    font-size: 18.72px;
    font-family: 'Roboto';
    font-weight: 700;
    margin: 16px 0;
`

const Text = styled.h4`
    ${fonts.jotaBody()}
    color: ${colors.gray.jotaGrayDarker}
`

const Link = styled.a`
    display: inline-block;
    font-weight: 700;
    text-decoration: none;
    color: ${colors.orange.jotaOrange};
    font-size: 16px;
    text-decoration: underline;

    &:hover {
        color: ${colors.orange.jotaOrange};
    }
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`
