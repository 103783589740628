import React from 'react'
import styled from 'styled-components'
import { ReactComponent as LogoJOTA } from './../static/img/jota-logo.svg'

const Home = () => {
    return (
        <HomeContainer>
            <Logo />
            <h1 style={{ color: 'white' }}>aguarde, em breve</h1>
        </HomeContainer>
    )
}

export default Home

const HomeContainer = styled.div`
    background: #232323;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const Logo = styled(LogoJOTA)`
    max-width: 300px;
`
