import React, { FC, useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Breadcrumb, CategoryName } from './components'
import { connect } from 'react-redux'
import {
    getContent,
    getContent5Last,
    setErrorStatus,
} from '../../flux/ducks/newsletter'
import { Card, Dialog, Tag } from '@JOTAJornalismo/jota-design-system'
import { withRouter, useHistory } from 'react-router-dom'
import {
    cleanMCTable,
    colorByVertical,
    dateConvert,
    getUserDataMixPanel,
    hasPermission,
} from './../../utils/helpers'
import { colors, MOBILE_SIZE } from 'src/static/theme'
import { BACKOFFICE_RESPONSES, ERROR, URLS } from './../../utils/constants'
import { Header, MenuComponent } from 'src/components'

import {
    NewslettersContent5LastLoading,
    NewslettersContentLoading,
} from '../../utils/loading'
import hotjarStart from './../../utils/hotjarStart'
import { mixPanelSetData } from '../../utils/mixpanel'
import { getColorsByName } from './components/categoryName'
import { changePage } from './../../flux/ducks/menu'
import {
    Container,
    Content,
    HeaderPage,
    Row,
} from 'src/components/page-structure'

type NewslettersProps = {
    content: any
    getNewslettersContent: Function
    getNewsletters5LastProps: Function
    getActivePageProps: Function
    setErrorStatusProps: Function
    loading: boolean
    location: any
    match: any
    ultimas: any
    contentStatus: string
    contentUltimasStatus: string
    user: any
    roles: any
    menu: any
    statusContent: string
    userBO: any
}

type loadingType = {
    content: boolean
    fiveLast: boolean
}

const NewsletterContent: FC<NewslettersProps> = ({
    getNewslettersContent,
    getNewsletters5LastProps,
    getActivePageProps,
    setErrorStatusProps,
    loading,
    content,
    match,
    ultimas,
    contentUltimasStatus,
    user,
    roles,
    menu,
    statusContent,
    userBO,
}) => {
    const [isLoading, setIsLoading] = useState<loadingType>({
        content: true,
        fiveLast: true,
    })

    const [isFromSearch, setIsFromSearch] = useState<boolean>(false)
    const [mixPanelFire, setMixPanelFire] = useState<boolean>(false)
    const [mixPanelFullContent, setMixPanelFullContent] =
        useState<boolean>(false)
    const contentHeight = useRef<HTMLElement>(null)
    const history = useHistory()

    let mixPanelControl = 0

    const nlVertical =
        content &&
        content.category &&
        content.category.vertical &&
        content.category.vertical.name.toLowerCase()

    const data = {
        user: getUserDataMixPanel(user, userBO, 'user'),
        company: getUserDataMixPanel(user, userBO, 'company'),
    }

    /**
     * useEffect Constructor
     */
    useEffect(() => {
        /**
         * Load Newsletter content by ID
         */
        getNewslettersContent(match.params.id)

        /**
         * Load last 5 contents
         */
        getNewsletters5LastProps()

        /**
         * Change active page
         * Change menu
         */
        getActivePageProps('')

        /**
         * Theme Color
         * Description: Apply the main color for this product on browser header
         */
        const tag = document.querySelector(
            'meta[name="theme-color"]'
        ) as HTMLHeadElement
        tag.setAttribute('content', `${colors.green.jotaGreen}`)

        /**
         * Come from Search?
         */
        if (match.params.search && match.params.search === 'busca') {
            setIsFromSearch(true)
        }

        return () => {
            setMixPanelFire(false)
            setMixPanelFullContent(false)
            mixPanelControl = 0
        }
    }, [match.params.id])

    /**
     * useEffect
     * Error on Loading API
     */
    useEffect(() => {
        if (statusContent === 'error') {
            setIsLoading({ ...isLoading, content: false })
        }

        return () => {
            setIsLoading({ content: true, fiveLast: true })
        }
    }, [statusContent])

    /**
     * HotJar
     */
    const hotJar = () => {
        if (user && user.attributes && user.attributes.email) {
            hotjarStart(
                {
                    email: user.attributes.email,
                    name: user.attributes.name,
                    roles: roles,
                },
                'tributos-content',
                `/tributos/${match.params.id}`
            )
        }
    }

    /**
     * MixPanel
     */
    const mixPanel = () => {
        if (!mixPanelFire && content.title) {
            mixPanelSetData({
                id: user && user.username,
                date: content && content.send_time,
                JOTA_title: 'JOTA PRO - content',
                JOTA_type: 'content',
                data: {
                    JOTA_content_title: content.title,
                    JOTA_content_date: content.send_time,
                    JOTA_content_category:
                        content && content.category && content.category.name,
                    JOTA_vertical: content.category.vertical.name,
                },
                user: data.user,
                company: data.company,
            })
            setMixPanelFire(true)
        }
    }

    /**
     * UseEffect Content is Loaded
     * Fire: MixPanel, Hotjar
     */
    useEffect(() => {
        /**
         * Window Scroll to Top
         */
        window.scrollTo(0, 0)

        /*
         * Check if user has permission to see this newsletter
         */
        if (nlVertical && !hasPermission(roles, nlVertical)) {
            setErrorStatusProps(BACKOFFICE_RESPONSES.NO_ACCESS_VERTICAL)
            history.push(URLS.root)
        }

        if (isLoading.content && statusContent !== 'error') {
            hotJar()
            mixPanel()
            mixPanelFullContentCheck()
        }

        return () => {
            setMixPanelFullContent(false)
        }
    }, [content.title])

    /**
     * UseEffect - update loading
     */
    useEffect(() => {
        setIsLoading({
            ...isLoading,
            content: loading,
            fiveLast: ultimas.loading,
        })

        return () => {
            setIsLoading({
                content: true,
                fiveLast: true,
            })
            setMixPanelFullContent(false)
        }
    }, [loading, ultimas.loading])

    /**
     * Mix Panel
     * Scroll - full content is loaded
     */
    const mixPanelFullContentCheck = () => {
        mixPanelControl = 0
        window.onscroll = function () {
            if (
                window.innerHeight + window.scrollY >=
                document.body.scrollHeight - 400
            ) {
                if (!mixPanelFullContent) {
                    setMixPanelFullContent(true)

                    if (mixPanelControl === 0) {
                        mixPanelControl = 1
                        mixPanelSetData({
                            id: user && user.username,
                            date: content && content.send_time,
                            JOTA_title: 'JOTA PRO - content full',
                            JOTA_type: 'content full',
                            data: {
                                JOTA_vertical:
                                    content && content.category.vertical.name,
                                JOTA_content_title: content && content.title,
                                JOTA_content_date: content && content.send_time,
                                JOTA_content_category:
                                    content &&
                                    content.category &&
                                    content.category.name,
                            },
                            user: data.user,
                            company: data.company,
                        })
                    }
                    setMixPanelFullContent(true)
                }
            }
        }
    }

    /**
     * Tags Content
     * @return {React.Element}
     */
    const TagsContentBody = () =>
        content.tags &&
        content.tags.length > 0 && (
            <Tags>
                <span>Tags</span>

                <TagsContent>
                    {content.tags &&
                        content.tags.map(
                            (
                                tag: { id: string; tag: string; alias: string },
                                key: number
                            ) => (
                                <Tag
                                    label={tag.tag}
                                    key={key}
                                    size='large'
                                    color='black'
                                    type='outline'
                                    onClick={() => {
                                        mixPanelSetData({
                                            id: user && user.username,
                                            date: content && content.send_time,
                                            JOTA_title: 'JOTA PRO - tag source',
                                            JOTA_type: 'JOTA PRO - tag source',
                                            data: {
                                                JOTA_vertical:
                                                    content.category.vertical
                                                        .name,
                                                JOTA_content_title:
                                                    content.title,
                                                JOTA_content_date:
                                                    content.send_time,
                                                JOTA_content_category:
                                                    content &&
                                                    content.category &&
                                                    content.category.name,
                                                JOTA_tag_source: tag.tag,
                                                JOTA_tag_id: tag.id,
                                                JOTA_tag_alias: tag.alias,
                                                JOTA_tag_source_content_id:
                                                    match.params.id,
                                            },
                                            user: data.user,
                                            company: data.company,
                                        })
                                        window.scrollTo(0, 0)
                                        history.push(
                                            URLS.newsletter.tagSet(tag.tag)
                                        )
                                    }}
                                />
                            )
                        )}
                </TagsContent>
            </Tags>
        )

    /**
     * Show Content
     * Description: Check and show content by id
     * if's error show Dialog
     * @return {any}
     */
    const showContent = (): any => {
        if (!isLoading.content && content && content.category) {
            return (
                <LayoutNews>
                    {statusContent === ERROR ? (
                        <Dialog
                            type={ERROR}
                            message={BACKOFFICE_RESPONSES.NO_API}
                        ></Dialog>
                    ) : (
                        <div>
                            <Breadcrumb
                                fromSearch={isFromSearch}
                                items={[
                                    { name: 'Home', route: URLS.root },
                                    {
                                        name:
                                            content.category &&
                                            content.category.name,
                                        route: URLS.newsletter.categoriaSet(
                                            content.category.vertical.name,
                                            content.category.id
                                        ),
                                    },
                                ]}
                            />
                            <CategoryName
                                name={content.category && content.category.name}
                                icon={content.category && content.category.icon}
                                vertical={content.category.vertical.name}
                                color={colorByVertical(
                                    content.category.vertical.name.toLowerCase()
                                )}
                            />
                            <CardContainer>
                                <Card
                                    newsLink=''
                                    size='xlarge'
                                    title={content.title}
                                    authorTagColor='gray'
                                    authorTagLabel={
                                        dateConvert(content.send_time).date
                                    }
                                    relatedTagColor='gray'
                                    relatedTagLabel={
                                        dateConvert(content.send_time).time
                                    }
                                    relatedTagDisableHover
                                    authorTagDisableHover
                                    titleSearchTerm=''
                                    titleDisableHover
                                    isLinkExternal={false}
                                />
                            </CardContainer>

                            <HTMLContent>
                                <HTML
                                    dangerouslySetInnerHTML={{
                                        __html: cleanMCTable(
                                            content.html_content
                                        ),
                                    }}
                                    color={'black'}
                                />
                            </HTMLContent>

                            <TagsContentBody />
                        </div>
                    )}
                </LayoutNews>
            )
        } else if (statusContent === ERROR) {
            return (
                <div>
                    <Dialog
                        message={BACKOFFICE_RESPONSES.NO_ACCESS_VERTICAL}
                        type={ERROR}
                    />
                </div>
            )
        } else {
            return <NewslettersContentLoading />
        }
    }

    /**
     * Return 5 Last
     * Description: Return last 5 content from an api
     * @return {any} React Element
     */
    const return5Last = (): any => {
        if (!isLoading.fiveLast) {
            if (ultimas && ultimas.items && ultimas.items.length > 0) {
                return ultimas.items.map((item: any, key: number) => {
                    return (
                        <CardContainer key={key}>
                            <Card
                                subjectTagColor={colorByVertical(
                                    item.category.vertical.name.toLowerCase()
                                )}
                                subjectTagLabel={item.category.vertical.name}
                                subjectTagType='filled'
                                subjectTagDisableHover
                                newsLink={URLS.newsletter.conteudoSet(
                                    item.category.vertical.name.toLowerCase(),
                                    item.id,
                                    false
                                )}
                                size='xsmall'
                                title={item && item.title}
                                authorTagColor='gray'
                                authorTagLabel={
                                    dateConvert(item.send_time).date
                                }
                                relatedTagColor='gray'
                                relatedTagLabel={
                                    dateConvert(item.send_time).time
                                }
                                tagColor={colorByVertical(
                                    item.category.vertical.name.toLowerCase()
                                )}
                                TagLeftIcon={
                                    item && item.category && item.category.icon
                                }
                                tagLabel={
                                    item && item.category && item.category.name
                                }
                                tagOnClick={() =>
                                    history.push(
                                        `${URLS.newsletter.categoriaSet(
                                            item.category.vertical.name.toLowerCase(),
                                            item.category.id
                                        )}`
                                    )
                                }
                                relatedTagDisableHover
                                authorTagDisableHover
                                titleSearchTerm=''
                            />
                        </CardContainer>
                    )
                })
            } else {
                if (contentUltimasStatus === ERROR) {
                    return (
                        <div>
                            <Dialog
                                message={
                                    BACKOFFICE_RESPONSES.NO_ACCESS_VERTICAL
                                }
                                type={ERROR}
                            />
                        </div>
                    )
                }
            }
        } else {
            return <NewslettersContent5LastLoading />
        }
    }

    /**
     * Return page content
     */
    return (
        <Container>
            <Header term='' />
            <Row>
                <MenuComponent />
                <Content
                    id='newsletter_content'
                    ref={contentHeight}
                    menuIsOpen={menu.isOpen}
                >
                    <LayoutDistribution>
                        <LayoutTop>
                            {showContent()}
                            <LayoutLastNews>
                                <HeaderPage>Últimas</HeaderPage>
                                {return5Last()}
                            </LayoutLastNews>
                        </LayoutTop>
                    </LayoutDistribution>
                </Content>
            </Row>
        </Container>
    )
}

/**
 * Map State to Props
 * @param {state} state
 * @return {redux}
 */
const mapStateToProps = (state: any) => ({
    content: state.newsletters.content.content,
    loading: state.newsletters.content.loading,
    ultimas: state.newsletters.content.ultimas,
    contentStatus: state.newsletters.content.status,
    contentUltimasStatus: state.newsletters.content.ultimas.status,
    user: state.auth.user,
    roles: state.auth.roles,
    menu: state.menu,
    statusContent: state.newsletters.content.status,
    userBO: state.auth.userBO,
})

/**
 * Map Dispatch to Props
 * @param {dispatch} dispatch
 * @return {event}
 */
const mapDispatchToProps = (dispatch: any) => ({
    getNewslettersContent: (id: number) => dispatch(getContent(id)),
    getNewsletters5LastProps: () => dispatch(getContent5Last()),
    getActivePageProps: (page: string) => dispatch(changePage(page)),
    setErrorStatusProps: (msg: string) => dispatch(setErrorStatus(msg)),
})

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(NewsletterContent)
)

const LayoutDistribution = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
`
const LayoutTop = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: ${MOBILE_SIZE}) {
        flex-direction: column;
    }
`

const LayoutNews = styled.div`
    width: 70%;
    margin-right: 36px;

    @media (max-width: ${MOBILE_SIZE}) {
        width: 100%;
    }
`

const LayoutLastNews = styled.div`
    width: 30%;
    padding-top: 28px;

    @media (max-width: ${MOBILE_SIZE}) {
        width: 100%;
    }
`

export const CardContainer = styled.div`
    margin-bottom: 36px;
    margin-top: 36px;
`

const HTMLContent = styled.div`
    display: flex;
`
const HTML = styled.p<{ color: string }>`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
    width: 100%;
    margin: 0;

    @media (max-width: ${MOBILE_SIZE}) {
        box-sizing: border-box;
        padding-right: 18px;
    }

    table {
        text-align: left !important;
        padding: 0 !important;
        font-family: 'Roboto' !important;

        h1 {
            color: ${(props) =>
                props.color
                    ? getColorsByName(props.color)
                    : colors.green.jotaGreen} !important;
            font-family: 'Roboto' !important;
            font-size: 1.2rem !important;
            text-align: left !important;
            margin-top: 16px !important;
            display: block !important;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: ${colors.black.jotaBlack} !important;
            padding: 0 !important;
            display: block !important;
            margin-top: 24px !important;
            margin-bottom: 8px !important;
            font-style: inherit !important;
            font-family: Roboto !important;
        }

        h2 {
            font-style: normal !important;
            font-weight: bold !important;
            font-size: 24px !important;
            line-height: 32px !important;
            display: flex !important;
            align-items: center !important;
            font-feature-settings: 'pnum' on, 'lnum' on !important;
        }

        h3 {
            font-weight: 700 !important;
            font-size: 1.25rem !important;
            line-height: 1.5rem !important;
            text-transform: uppercase !important;
        }

        h4 {
            font-weight: 700 !important;
            font-size: 18px !important;
            line-height: 24px !important;
            letter-spacing: 0 !important;
        }

        h5 {
            font-weight: 700 !important;
            font-size: 0.875rem !important;
            line-height: 1.25rem !important;
            text-transform: uppercase !important;
        }

        p {
            margin-bottom: 24px;
        }

        td {
            padding-left: 0 !important;
        }

        /* [data-file-id] {
            & < td {
                background: green;
            }
        } */

        h2.mc-toc-title {
            margin-right: 24px;
        }

        td.mcnButtonContent {
            padding-left: 18px !important;
        }

        a {
            color: ${colors.black.jotaBlack} !important;
        }
        span {
            color: black;
        }

        .mcnButton {
            color: white !important;
        }
    }
`
const Tags = styled.div`
    display: flex;
    flex-direction: row;
    span {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 27px;
        margin-right: 12px;
        display: flex;
        text-transform: uppercase;

        color: ${colors.black.jotaBlack};
    }

    @media (max-width: ${MOBILE_SIZE}) {
        align-items: flex-start;
    }
`

const TagsContent = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;

    @media (max-width: ${MOBILE_SIZE}) {
    }
`
