/* eslint-disable no-unused-vars */
import React, { ReactElement, useEffect, useState } from 'react'
import { Input, Button, Dialog } from '@JOTAJornalismo/jota-design-system'
import LogoJota from './../../static/img/jotapro.png'
import './../../styles/pages/reset-password.scss'
import { connect } from 'react-redux'
import { AppDispatch, AppState } from './../../flux/types/auth'
import { forgotPasswordSubmit } from './../../flux/ducks/auth'
import queryString from 'query-string'
import { withRouter } from 'react-router-dom'
import { COGNITO_WARNINGS, URLS } from './../../utils/constants'
import { COGNITO_RESPONSES } from '../../utils/constants'
import { PasswordChangeForm } from 'src/components'

type Props = {
    authState: string
    changeAuthState: (state: string) => void
    forgotPasswordSubmitProps: (
        email: string,
        code: string,
        password: string
    ) => void
    loading: boolean | null
    error: string
    user: any
    passwordStatusCheck: boolean
    history: any
}

enum statusValue {
    success = 'success',
    error = 'error',
    normal = 'reset-password',
}

/**
 * Reset Password
 * Description: Route valid for the user change password
 * Important: Password only will be change if CODE from Cognito it's be correct
 *
 * @return {void}
 */
const ResetPassword: React.FC<Props> = ({
    authState,
    forgotPasswordSubmitProps,
    loading,
    error,
    user,
    passwordStatusCheck,
    history,
}) => {
    /**
     * UseState Variables
     * Description:
     * Variables that control the flow of change password
     */

    const [sent, setSent] = useState<statusValue>(statusValue.normal)

    const [paramsValue, setParamsvalue] = useState<{
        username: string
        code: string
        password: string
        passwordStatus: boolean
        reset: boolean
    }>({
        username: '',
        code: '',
        password: '',
        passwordStatus: false,
        reset: false,
    })

    const [isLoading, setIsLoading] = useState<boolean | null>(null)

    const [errorMessage, setErrorMessage] = useState<string>('')

    /**
     * Submit Change Password
     * Steps:
     * 1 - It's a valid password types
     * 2 - Cognito will check if code is valid
     * 3 - Change password
     */
    const onClickResetPassword = () => {
        if (!paramsValue.passwordStatus) return
        forgotPasswordSubmitProps(
            paramsValue.username,
            paramsValue.code,
            paramsValue.password
        )

        // history.push(URLS.login)
    }

    /**
     * Constructor
     * Description: Initialize variables and check params values
     */
    useEffect(() => {
        setIsLoading(loading)
        const params = queryString.parse(location.search) as any
        setParamsvalue({
            ...paramsValue,
            code: params.code,
            username: params.username,
        })

        return () => {
            setParamsvalue({
                code: '',
                username: '',
                password: '',
                passwordStatus: false,
                reset: false,
            })
        }
    }, [])

    /**
     * Error Event
     */
    useEffect(() => {
        if (!loading && error && !passwordStatusCheck) {
            switch (error) {
                case COGNITO_WARNINGS.LIMIT_EXCEEDED:
                    setErrorMessage(COGNITO_RESPONSES.LIMIT_EXCEEDED)
                    break
                case COGNITO_WARNINGS.EXPIRED_CODE:
                    setErrorMessage(COGNITO_RESPONSES.EXPIRED_CODE)
                    break
                case COGNITO_WARNINGS.USERNAME_EMPTY:
                    setErrorMessage(COGNITO_RESPONSES.USERNAME_EMPTY)
                    break
            }
            setSent(statusValue.error)
        } else if (authState === COGNITO_WARNINGS.FORGOT_PASSWORD_RESET) {
            setSent(statusValue.success)
        }
    }, [passwordStatusCheck, loading, error, authState, paramsValue.password])

    /**
     * Error Message
     * Description: Return error message
     * @return {ReactElement}
     */
    const errorMessageFragment = () => {
        return (
            <React.Fragment>
                <h2>Link expirado</h2>
                <div className='reset-password-dialog-container'>
                    <Dialog message={errorMessage} type='error' />
                </div>
                <Button
                    label='Tentar Novamente'
                    size='medium'
                    type='filled'
                    width='100%'
                    onClick={() => {
                        setErrorMessage('')

                        // redirect to Forgot Page
                        history.push(URLS.esqueci)
                    }}
                />
            </React.Fragment>
        )
    }

    /**
     * Reset Form
     * Description: Render a reset form
     * @return {ReactElement}
     */
    const resetForm = () => {
        return (
            <React.Fragment>
                <h1>Nova senha</h1>
                <p>Utilize os campos abaixo para criar uma nova senha.</p>

                <PasswordChangeForm
                    state={paramsValue}
                    setState={setParamsvalue}
                    reset={paramsValue.reset}
                />

                <Button
                    label='Redefinir a senha'
                    size='medium'
                    type='filled'
                    disabled={!paramsValue.passwordStatus}
                    width='100%'
                    onClick={() => onClickResetPassword()}
                />
            </React.Fragment>
        )
    }

    /**
     * Show Form
     * @return {ReactElement}
     */
    const showForm = (): ReactElement => {
        if (sent === 'success') {
            return (
                <React.Fragment>
                    <h2>Tudo certo!</h2>
                    <div className='reset-password-dialog-container'>
                        <Dialog
                            message={COGNITO_RESPONSES.PASSWORD_CHANGE}
                            type='success'
                        />
                    </div>
                    <Button
                        label='Efetuar Login'
                        size='medium'
                        type='filled'
                        width='100%'
                        onClick={() => history.push(URLS.login)}
                    />
                </React.Fragment>
            )
        } else if (errorMessage) {
            return errorMessageFragment()
        } else {
            return resetForm()
        }
    }

    return (
        <div className='reset-password-page'>
            <div className='reset-password-container'>
                <div className='reset-password-image-container'>
                    <img src={LogoJota} alt='JOTA PRO logo' />
                </div>
                {isLoading ? <h3>Aguarde</h3> : showForm()}
            </div>
        </div>
    )
}

export const mapStateToProps = (state: AppState) => ({
    user: state.auth.user,
    authState: state.auth.authState,
    loading: state.auth.loading,
    error: state.auth.error,
    passwordStatusCheck: state.auth.statusPassword,
})

export const mapDispatchToProps = (dispatch: AppDispatch) => ({
    forgotPasswordSubmitProps: (
        email: string,
        code: string,
        password: string
    ) => dispatch(forgotPasswordSubmit(email, code, password)),
})

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
)
